
import { IRid } from '../../models/IRid.bin';
import { rid } from '../../models/orientdb/CommonTypes.bin';
import { ref_Customers } from '../../models/orientdb/ref_Customers.bin';
import { Action } from '../client/ref_Estimates.bin';
import { ImportsSourceType } from './ref_Imports.bin';
import { Indicateur } from 'adwone-engine/index.bin';

export enum CronType {
    Import = 'Import',
    MDReport = 'MDReport',
    LEGO = 'LEGO',
    ExportAuto = 'ExportAuto',
    Notify = 'Notify',
    GitScript = 'GitScript',
    MediaSpecs = 'MediaSpecs'
}

type Recurrence = {
    DayOfWeek: number[];
    Hour: number;
    Minute: number;
};

type Planned = {
    Date: Date;
    LoopSecond?: number;
};

export type ref_CronsTypes = ImportCrons | MDRaportCrons | LEGOCrons | NotifyCrons | GitScriptCrons | ExportAutoCrons;

export class ref_Crons implements IRid {
    '@rid'?: rid;
    Active: boolean = true;
    Name: string = "";
    Schedule: {
        Recurrence?: Recurrence;
        Planned?: Planned;
    } = {
            Recurrence: {
                DayOfWeek: [1, 2, 3, 4, 5],
                Hour: 9,
                Minute: 30,
            },
        };
    LastExecutionDate?: Date;
    Created_at?: Date;
    Updated_at?: Date;
    CronType: CronType = CronType.Import;
    TaskParams?: ImportCrons['TaskParams'] | MDRaportCrons['TaskParams'] | LEGOCrons['TaskParams'] | NotifyCrons['TaskParams'] | GitScriptCrons['TaskParams'] | ExportAutoCrons['TaskParams'] | MediaSpecsCrons['TaskParams'] = ({} as any);
}

export class NotifyCrons extends ref_Crons {
    CronType = CronType.Notify;
    TaskParams: {
        ReportID: string;
        EstimateID: string;
        UserID: string;
        NotificationMessage?: string;
        EstimateType?: Action;
    };
    Schedule: {
        Recurrence?: Recurrence;
        Planned?: Planned;
    } = {
            Planned: {
                Date: new Date(),
                LoopSecond: 3,
            },
        };
    getParams = (): {} => ({});
}


export class ImportCrons extends ref_Crons {
    CronType = CronType.Import;
    TaskParams: {
        ImportID: string;
        SourceType: ImportsSourceType;
    };
    getParams = () => {
        return {
            ImportID: "#123:123",
            SourceType: "MAP",
        };
    };
}

export class MediaSpecsCronsParams {
    API_token_url: string = "https://keycloak.mediaspecs.eu/realms/mediaspecs-fr/protocol/openid-connect/token";
    API_client_id: string = "ad-wanted";
    API_client_secret: string = "NdMJEqBQRQH7RU7kaHH2W6ZyCt9FraL1";

    AdwOne_serverHost: string = "http://localhost:5000/mediaspecs/";
    Adwone_header_authorization = "Er26E0vGGfxi3xTPME7xtw+q0TFYRWd6BOsEYBDixPEWHA5S4Ix0t6KEU8E+d5B/FQC4oB6xnYYrQ2u9j8r/fA==";

    ImportFormats?: boolean = false;
    PageIndex?: number = 0;
    PageSize?: number = 20;
}

export class MediaSpecsCrons extends ref_Crons {
    CronType = CronType.MediaSpecs;
    TaskParams: MediaSpecsCronsParams;
    getParams = () => {
        return new MediaSpecsCronsParams();
    };
}

export class MDRaportCrons extends ref_Crons {
    CronType = CronType.MDReport;
    TaskParams: {
        Recipients: string[];
        Date?: {
            Start: Date;
            End: Date;
        }
    };
    getParams = () => {
        return {
            Recipients: ["toto@adresse.com", "tata@mail.com"],
            Date: {
                Start: new Date().toString(),
                End: new Date().toString(),
            }
        };
    };
}

export class LEGOCrons extends ref_Crons {
    CronType = CronType.LEGO;
    TaskParams: {
        MessagesIndicateurs: Indicateur[];
        LastMessagesIndicateursUpdate: Date;
        HistoryMessagesIndicateurs: { indicateurs: Indicateur[], date: Date }[];

        Folder: string;
        Customer: ref_Customers["@rid"];
        ArchivesFolder?: string;
        ArchiveSize?: number;
        ExportsFilesName?: {
            Messages: string;
            Campaigns: string;
            Discounts: string;
        };
        FTP?: {
            Host: string,
            User: string,
            Password: string,
            Folder?: string
        };
    }
    getParams = () => {
        return {
            Folder: "./exemple/",
            Customer: "#123:123",
            ArchivesFolder: "./exemple/archives/",
            ArchiveSize: 7,
            ExportsFilesName: {
                Messages: "messages",
                Campaigns: "campaigns",
                Discounts: "discounts"
            },
            FTP: {
                Host: "ftp.adwone.fr",
                User: "dupont",
                Password: "mot de passe",
                Folder: "./exemple"
            }
        };
    };
}


export class ExportAutoCrons extends ref_Crons {
    CronType = CronType.ExportAuto;
    TaskParams: {
        MessagesIndicateurs: Indicateur[];
        LastMessagesIndicateursUpdate: Date;
        HistoryMessagesIndicateurs: { indicateurs: Indicateur[], date: Date }[];
        Filters: any;

        Folder: string;
        Customer: ref_Customers["@rid"];
        ArchivesFolder?: string;
        ArchiveSize?: number;
        ExportsFilesName?: {
            Messages: string;
            Campaigns: string;
            Discounts: string;
        };
        FTP?: {
            Host: string,
            User: string,
            Password?: string,
            PrivateKey?: string,
            Folder?: string
        };
    }
    getParams = () => {
        return {
            Folder: "./exemple/",
            Customer: "#123:123",
            ArchivesFolder: "./exemple/archives/",
            ArchiveSize: 7,
            ExportsFilesName: {
                Messages: "messages",
                Campaigns: "campaigns",
                Discounts: "discounts"
            },
            FTP: {
                Host: "ftp.adwone.fr",
                User: "dupont",
                Password: "mot de passe",
                Folder: "./exemple"
            }
        };
    };
}

export class GitScriptCrons extends ref_Crons {
    CronType: CronType.GitScript;
    TaskParams: {
        scriptID: string;
    }

    getParams = () => {
        return {
            scriptID: ''
        }
    }
}
