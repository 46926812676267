
import * as React from "react";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { clone, hasOwnProperty, propertyOf, removeDiacritics } from "hub-lib/tools.bin";
import { Notify } from "../../../utils/Notify.bin";
import { Trad, TradProp } from "trad-lib";
import { IsDebugMode } from "../../../utils/localstorage.bin";
import { ColumnIndicateur, Indicateur, IndicateurToString } from "adwone-engine/index.bin";
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory'
import { CustomIconButton } from "../Generic/CustomIconButton";
import { AModeleCreatorBase, ModeleCreatorBaseProps } from "./ModeleCreatorBase";
import { getMultiSelectTreeValue, ItemProps, MultiSelectTree, MultiSelectTreeChangeEvent, MultiSelectTreeExpandEvent } from "@progress/kendo-react-dropdowns";
import { Slider } from "@progress/kendo-react-inputs";
import { eColumnType } from "hub-lib/models/types.bin";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { GetDragCell } from "../Messages/DiscountCategoryArray.bin";
import { getter } from "@progress/kendo-data-query";
import { ContainerComponent } from "../Generic/ContainerComponent";
import { filterBy } from "@progress/kendo-react-data-tools";
import { getIcon } from "adwone-lib/components/IconManager";
import { ADWProperty } from "hub-lib/types";
import { ElementContainer } from "../Generic/Common.bin";
import MultipleCombo from "../../crossedTable/MultipleCombo.bin";
import { dragCol } from "../Messages/DiscountEditor/DiscountEditor.bin";
import conf from "adwone-lib/rollup.config";
import { RootState, store } from "../../../redux/store";
import { RowSize } from "../../../styles/theme";
import { TooltipManager } from 'adwone-lib/index';
import { IndicateurToDefinition } from "../Generic/ModeleGrid/ModeleGrid";

export class TProps extends ModeleCreatorBaseProps<ref_TableConfigurations> {
    hideViewSelector?: boolean;
}

let timeoutTriggerChanged = undefined;

/**
 * Composant de création d'un modèle de tableau (sélection des indicateurs)
 */
export class ModeleColumnsCreator extends AModeleCreatorBase<TProps, ref_TableConfigurations> {

    dimensions: (ADWProperty | Indicateur)[] = undefined;

    constructor(props: TProps) {
        super(props);
        this.selector = (root: RootState) => root.columsConfigurations.configurations?.[this.props.type]?.table
    }

    afterRender() {
        const { hideViewSelector, type } = this.props;
        const configuration = clone(this.selector(store.getState())) ?? new ref_TableConfigurations;

        if (!this.dimensions) {
            AggregatorFactory.GetInstance().Get(type).Provide().then(inds => {
                this.dimensions = inds
                    .filter(i => i.columnType == eColumnType.Property)
                    .map(i => i.indicateur)
                    .sort((a, b) => a.name.localeCompare(b.name));
                this.forceUpdate();
            });
        }
        return <>
            {
                configuration &&
                <ColumnEditor
                    key={`editor-${configuration?.["@rid"]}`}
                    type={type}
                    onChange={this.onChange}
                    configuration={configuration as any} />
            }
            {/** *** VENTILATIONS LIGNES *** */}
            {
                !hideViewSelector && this.dimensions && configuration.ViewMode != "Table"
                && <div style={{
                    backgroundColor: 'white',
                    marginTop: 'auto',
                    position: 'sticky',
                    bottom: 0
                }}>
                    <ElementContainer>
                        <MultipleCombo
                            key={`grouping-${configuration?.["@rid"]}-${this.dimensions?.length}`}
                            max={2}
                            label={Trad("grouping")}
                            choices={this.dimensions}
                            defaultValue={{ field: "*", type: null }}
                            initValue={configuration.CrossedtableConfig?.rowventils ?? []}
                            onChange={(ventilations: (ADWProperty | Indicateur)[]) => {
                                if (!configuration.CrossedtableConfig) configuration.CrossedtableConfig = {};
                                configuration.CrossedtableConfig.rowventils = ventilations;

                                this.onChange(configuration);
                            }}
                            /* TODO: type */
                            transform={(str) => str['name'] ?? TradProp(str.field)} />
                    </ElementContainer>
                </div>
            }
        </>
    }
}


export class ColumnEditorProps {
    configuration: ref_TableConfigurations;
    onChange: (configuration: ref_TableConfigurations) => void;
    type: string;
    //grid: VertexGrid<any>;
}


type ElementT = { key: string, value: any, checked: boolean, items: ElementT[] };
class ColumnTreeData {
    key: string;
    value: eColumnType | Indicateur;
    label: string;
    filterLabel: string;
    items: ColumnTreeData[];
}
const dataItemKey = "key";
const checkField = "checked";
const checkIndeterminateField = "checkIndeterminate";
const subItemsField = "items";
const expandField = "expanded";
const textField = "label";

const fields = {
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
};

export class ColumnEditorState {
    data: ColumnTreeData[];
    version: number;
    filter: any;
    activeItem: any;
    expanded: any[];
    value: any[];
    indicateurs: Indicateur[];
    // showAll: Boolean
}

class ColumnEditor extends React.Component<ColumnEditorProps, ColumnEditorState> {
    private _el: HTMLDivElement;

    constructor(props: ColumnEditorProps) {
        super(props);
        this.state = {
            version: 0,
            data: undefined,
            filter: undefined,
            activeItem: undefined,
            expanded: [],
            // showAll: false,
            value: props.configuration?.Columns?.map(c => {
                /** Supprime le nom pour checker l'égalité car la langue a pu changer */
                return { key: IndicateurToString(c), value: c };
            }) ?? [],
            indicateurs: []
        }
    }

    async componentDidMount() {
        const options = await AggregatorFactory.GetInstance().Get(this.props.type).Provide();

        const data: ColumnTreeData[] = [];
        for (const option of options) {
            let group = data.find(d => d.value == option.columnType);
            if (!group) {
                group = {
                    key: option.columnType,
                    value: option.columnType,
                    items: [],
                    label: Trad(option.columnType),
                    filterLabel: removeDiacritics(Trad(option.columnType))
                };
                data.push(group);
            }
            group.items.push({
                key: IndicateurToString(option.indicateur),
                value: option.indicateur,
                items: undefined,
                label: Trad(option.indicateur.name),
                filterLabel: removeDiacritics(Trad(option.indicateur.name))
            });
        }
        this.setState({ data, indicateurs: options.map(i => i.indicateur) })
    }


    filterChange = (event) => {
        event.filter.field = "filterLabel";
        if (event.filter.value.length > 2)
            this.setState({ filter: event.filter })
        else
            this.setState({ filter: undefined })
    }

    expandedState = (
        item: unknown,
        dataItemKey: string,
        expanded: unknown[]
    ) => {
        const nextExpanded = expanded.slice();
        const keyGetter = getter(dataItemKey);
        const itemKey = keyGetter(item);
        const index = expanded.findIndex((currentKey) => {
            return currentKey === itemKey;
        });
        index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

        return nextExpanded;
    };

    onChange = (event: MultiSelectTreeChangeEvent) => {
        const { configuration } = this.props;

        let newValue: ElementT[] = getMultiSelectTreeValue(this.state.data, { ...fields, ...event, value: this.state.value });

        // reverse pour garder l'ordre d'ajout
        const newElementSelected = newValue.filter(v => v.checked == false).reverse();
        const alreadySelected = newValue.filter(v => v.checked == true || hasOwnProperty(v, "checked") == false);

        // console.log("[onChange][alreadySelected]", clone(alreadySelected));
        // console.log("[onChange][newElementSelected]", clone(newElementSelected));
        // console.log("[onChange][newValue]", clone(newValue));
        // console.log("[onChange][newValue]", clone([...alreadySelected, ...newElementSelected]));

        newValue = [...alreadySelected, ...newElementSelected];

        for (let v of newValue) {
            if (!configuration.Columns.some(c => IndicateurToString(c) == v.key))
                configuration.Columns.push(v.value);
        }
        configuration.Columns = configuration.Columns.filter(c => newValue.some(v => v.key == IndicateurToString(c)));
        if (configuration.FrozenPosition > configuration.Columns.length)
            configuration.FrozenPosition = configuration.Columns.length;
        this.props.onChange?.(configuration);

        this.setState({ value: newValue });
    }

    onExpandChange = (event: MultiSelectTreeExpandEvent) =>
        this.setState({ expanded: this.expandedState(event.item, "key", this.state.expanded) });

    reorder = (dataItem: any) => {
        const { configuration } = this.props;
        if (!this.state.activeItem || (this.state.activeItem === dataItem)) return;

        const reorderedData = configuration.Columns.slice();
        const prevIndex = reorderedData.findIndex((p: any) => (p === this.state.activeItem));
        const nextIndex = reorderedData.findIndex((p: any) => (p === dataItem));
        reorderedData.splice(prevIndex, 1);
        reorderedData.splice(nextIndex, 0, this.state.activeItem);

        if (JSON.stringify(configuration.Columns) != JSON.stringify(reorderedData)) {
            configuration.Columns = reorderedData;
            this.forceUpdate();
            clearTimeout(timeoutTriggerChanged);
            timeoutTriggerChanged = setTimeout(() => {
                this.setState({ activeItem: undefined }, () => {
                    this.props.onChange?.(this.props.configuration);
                });
            }, 2000);
        }
    }

    dragStart = (dataItem: any) => {
        if (this.state.activeItem != dataItem)
            this.setState({ activeItem: dataItem });
    }

    onRemove = async (col: Indicateur) => {
        const { configuration } = this.props;
        if (configuration.Default)
            return Notify(Trad("cannot_change_default_conf"), "error");

        configuration.Columns = configuration.Columns.filter(cc => IndicateurToString(cc) !== IndicateurToString(col));
        this.props.onChange?.(configuration);
        this.setState({ value: this.state.value.filter(v => v.key != IndicateurToString(col)) })
    }

    findIndixIndicator = (col: Indicateur, cols: Indicateur[]) => {
        return cols.findIndex(c => IndicateurToString(c) === IndicateurToString(col));
    }

    onClickSchedulerVisibility = async (col: ColumnIndicateur) => {
        const { configuration } = this.props;
        col.isSchedulerHidden = !Boolean(col.isSchedulerHidden);
        // this.setState({showAll: Boolean(this.state.showAll)})
        this.props.onChange?.(configuration);
    }
    onClickSchedulerAllVisibility = (columns) => {
        // console.log(this.state.showAll)
        // this.setState({showAll: !Boolean(this.state.showAll)})
        const { configuration } = this.props;

        // si certaines colonnes sont cachées, on les affiche toutes
        const showAll = columns.some(col => col.isSchedulerHidden);

        columns.forEach(col => {
            // col.isSchedulerHidden = !Boolean(this.state.showAll);
            col.isSchedulerHidden = !showAll;
        });
        this.props.onChange?.(configuration);
    }
    checkAllSelected = (columns) => {
        return columns.every(({ isSchedulerHidden }) => !isSchedulerHidden);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.activeItem != this.state.activeItem)
            return false;
        return true;
    }

    processMultiSelectTreeData = (tree, options): ColumnTreeData[] => {

        const {
            subItemsField = "items",
            checkField = "checked",
            checkIndeterminateField = "checkIndeterminate",
            expandField = "expanded",
            dataItemKey,
            value,
            filter,
            expanded,
        } = options;

        const filtering = Boolean(filter && filter.value);

        for (const group of tree) {
            group[expandField] = expanded.find(key => key == group.key) != undefined;
            let checked = 0;
            if (group[subItemsField])
                for (const indicateur of group[subItemsField]) {
                    indicateur[checkField] = value.find(v => v.key == indicateur.key) != undefined;
                    if (indicateur[checkField])
                        checked++;
                }
            group[checkField] = checked > 0 && checked == group[subItemsField].length;
            group[checkIndeterminateField] = !group[checkField] && checked > 0;
        }
        if (filtering) {
            filter.value = removeDiacritics(filter.value);
            const filterTree = filterBy(tree, [filter], subItemsField);
            for (const group of filterTree)
                group[expandField] = true;
            return filterTree;
        }
        return tree;
    }

    render() {
        const { configuration } = this.props;
        const { data, filter, expanded, value, indicateurs } = this.state;
        configuration?.Columns?.forEach(c => {
            const indicateur = indicateurs.find(i => IndicateurToString(i) == IndicateurToString(c));
            if (indicateur)
                c.name = indicateur.name;
        })

        if (IsDebugMode()) {
            console.log(`configuration?.Columns`, configuration?.Columns);
            console.log(`configuration?.FrozenPosition`, configuration.FrozenPosition);
        }
        const treeData = data ? this.processMultiSelectTreeData(data, { expanded, value, filter, ...fields }) : [];
        const Item = (props: ItemProps) => {

            const definition = IndicateurToDefinition(props.item.value);
            return (
                <React.Fragment>
                    <span onMouseOver={e => TooltipManager.Push({ target: e.target, text: definition })}>
                        {props.item.label}
                    </span>
                </React.Fragment>
            );
        };
        
        return (
            <div>
                {!configuration?.Default &&
                    <div ref={(el) => (this._el = el)} className="configuration-container" style={{ position: "relative" }}>
                        <ContainerComponent title={Trad("add_remove_columns")}
                            className="configuration-multiselect margin-top margin-bottom">
                            {data &&
                                <MultiSelectTree
                                    item={Item}
                                    key={`${this.state.version}-Autocomplete`}
                                    data={treeData}
                                    placeholder={Trad("add_columns")}
                                    dataItemKey={dataItemKey}
                                    textField={textField}
                                    subItemsField={subItemsField}
                                    checkField={checkField}
                                    checkIndeterminateField={checkIndeterminateField}
                                    expandField={expandField}
                                    filterable
                                    value={value}
                                    onChange={this.onChange}
                                    onExpandChange={this.onExpandChange}
                                    onFilterChange={this.filterChange}
                                    tags={value.length ? [{ text: `${value.length} ${Trad('columns')}`, data: [] }] : []}
                                    style={{ width: "100%" }}
                                    popupSettings={{ appendTo: this._el }}
                                />}
                        </ContainerComponent>
                    </div>
                }
                {configuration?.Columns && configuration.ViewMode === "Scheduler" &&
                    <div style={{ textAlign: 'right', width: '100%' }}>
                        <CustomIconButton
                            style={{ marginRight: RowSize + 1 }}
                            onClick={() => this.onClickSchedulerAllVisibility(configuration.Columns)}>
                            {getIcon(this.checkAllSelected(configuration.Columns) ? "visibility_off" : "visibility")}
                        </CustomIconButton>
                    </div>
                }
                <div style={{ display: "flex" }}>
                    <div>
                        <Slider className="configuration-slider"
                            step={1}
                            vertical={true}
                            min={0}
                            value={configuration?.Columns?.length - configuration.FrozenPosition}
                            max={configuration.Columns.length}
                            onChange={(event) => {
                                const newValue = configuration.Columns.length - Math.round(event.value);
                                if (newValue != configuration.FrozenPosition) {
                                    configuration.FrozenPosition = newValue;
                                    clearTimeout(timeoutTriggerChanged);
                                    timeoutTriggerChanged = setTimeout(() => {
                                        this.props.onChange?.(configuration);
                                    }, 1000);
                                    this.forceUpdate();
                                }
                            }} />
                    </div>
                    <div className='ColumnEditor-column-container' style={{ float: "left", width: "calc(100% - 20px)", marginTop: configuration.ViewMode === "Scheduler" ? 0 : 12 }}>

                        {/** Affichage des indicateurs déjà dans la configuration */}
                        {configuration?.Columns &&
                            <Grid data={configuration.Columns}

                                // onItemChange={this.itemChange}
                                className={"modelecreator-tab"}>
                                <GridColumn title="" width={dragCol} cell={GetDragCell(this.reorder, this.dragStart)} />
                                <GridColumn field={propertyOf<Indicateur>('name')} />
                                {configuration.ViewMode === "Scheduler" && <GridColumn
                                    cell={(c) => {
                                        const column: ColumnIndicateur = c.dataItem;
                                        return <td className="td-command-cell">
                                            <CustomIconButton onClick={() => this.onClickSchedulerVisibility(c.dataItem)}>
                                                {getIcon(column.isSchedulerHidden ? "visibility_off" : "visibility")}
                                            </CustomIconButton>
                                        </td>
                                    }}
                                    width={RowSize}
                                />}
                                <GridColumn
                                    cell={(c) => (
                                        <td className="td-command-cell">
                                            <CustomIconButton onClick={() => this.onRemove(c.dataItem)}>
                                                {getIcon("close")}
                                            </CustomIconButton>
                                        </td>
                                    )}
                                    width={RowSize}
                                />
                            </Grid>}
                    </div>
                </div>
            </div >);
    }
}
