import { Grid } from "@material-ui/core";
import { ADWColumn, AdwRow, getIcon, TooltipManager } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { ref_Crons } from "hub-lib/dto/admin/ref_Crons.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { propertyOf } from "hub-lib/tools.bin";
import moment from "moment";
import * as React from "react";
import { Trad } from "trad-lib";
import { eDialogMode } from "../../ConfigurableComponents/GenericDialog.bin";
import { JSONViewer } from "../../ConfigurableComponents/JSONViewer";
import CronsDialog from "../../VertexGrid/Crons/CronsDialog.bin";
import { DefaultCellActions } from "../../VertexGrid/DefaultCellActions";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { CustomIconButton } from "../../VertexGrid/Generic/CustomIconButton";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Loader from "../Loader";
import Template from "../Template";

let anchorId = 0;

const days = [
    {
        label: Trad("monday"),
        value: 1,
        shortLabel: Trad("monday").slice(0, 3),
    },
    {
        label: Trad("tuesday"),
        value: 2,
        shortLabel: Trad("tuesday").slice(0, 3),
    },
    {
        label: Trad("wednesday"),
        value: 3,
        shortLabel: Trad("wednesday").slice(0, 3),
    },
    {
        label: Trad("thursday"),
        value: 4,
        shortLabel: Trad("thursday").slice(0, 3),
    },
    {
        label: Trad("friday"),
        value: 5,
        shortLabel: Trad("friday").slice(0, 3),
    },
    {
        label: Trad("saturday"),
        value: 6,
        shortLabel: Trad("saturday").slice(0, 3),
    },
    {
        label: Trad("sunday"),
        value: 0,
        shortLabel: Trad("sunday").slice(0, 3),
    },
];

const defaultCron = new ref_Crons();

const CheckInServ = (props: { dataItem: ref_Crons }) => {
    const [data, setData] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    const getData = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        setData(await Client.searchVertexTyped(ref_Crons, { Active: true, schedule: props.dataItem["@rid"] }));
        setLoading(false);
    }

    return (<Grid container style={{ display: "flex", justifyContent: "center" }}>
        {loading ? <Loader /> :
            <>
                <CustomIconButton
                    className="primary_color"
                    onMouseOver={(e) =>
                        TooltipManager.Push({ target: e.target, text: Trad("reload") })
                    }
                    onClick={getData}
                >
                    {getIcon("reload")}
                </CustomIconButton>
                {data !== undefined ? (data !== null ? <JSONViewer data={data} /> : <CustomIconButton className="custom_btn_reset"
                    onMouseOver={(e) =>
                        TooltipManager.Push({ target: e.target, text: Trad("task_not_active_in_serv") })
                    }>{getIcon("close")}</CustomIconButton>) : ""}
            </>
        }
    </Grid>);
}

const Crons = () => {
    const [grid, setGrid] = React.useState(null);
    const scheduleToString = (schedule: ref_Crons["Schedule"]): string => {
        let result = "";
        if (schedule.Planned) {
            result += `${Trad("planned")}: ${moment(schedule.Planned.Date).format("LLLL")}`;
            if (schedule.Planned.LoopSecond) {
                result += ` ${Trad("every")} ${schedule.Planned.LoopSecond} ${Trad("seconds")}`;
            }
        }
        if (schedule.Recurrence) {
            result += `${Trad("recurrence")}: ${schedule.Recurrence.DayOfWeek.map(
                (dw) => days.find((d) => dw === d.value).shortLabel
            ).join(", ")}`;
            const time = `${schedule.Recurrence.Hour < 10 ? "0" + schedule.Recurrence.Hour : schedule.Recurrence.Hour
                }${schedule.Recurrence.Minute < 10
                    ? "0" + schedule.Recurrence.Minute
                    : schedule.Recurrence.Minute
                }`;
            result += ` ${Trad("at")} ${moment(time, "HHmm").format("LT")}`;
        }
        return result;
    };
    React.useEffect(() => {
        if (!grid) {
            const columns: ADWColumn<ref_Crons>[] = [];
            const hiddenProperties: string[] = [];
            const properties: string[] = [];
            hiddenProperties.push(propertyOf<ref_Crons>("Schedule"));
            hiddenProperties.push(propertyOf<ref_Crons>("TaskParams"));
            const scheduleColumn = new ADWColumn<ref_Crons>(
                Trad("Schedule"),
                "Schedule",
                ePropType.Any,
                false
            );
            scheduleColumn.cellValue = (CellValue, dataItem) => {
                return scheduleToString(dataItem.dataItem?.Schedule);
            };
            scheduleColumn.width = 400;
            columns.push(scheduleColumn);
            const taskParamsColumn = new ADWColumn<ref_Crons>(
                Trad("TaskParams"),
                "TaskParams",
                ePropType.String,
                false
            );
            taskParamsColumn.cellValue = (cellValue: any, dataItem?: AdwRow<ref_Crons>) => {
                return <JSONViewer data={dataItem.dataItem.TaskParams} />;
            };
            taskParamsColumn.width = 160;
            columns.push(taskParamsColumn);
            const taskInServ = new ADWColumn<ref_Crons>(
                Trad("taskInServ"),
                "taskInServ",
                ePropType.String,
                false
            );
            taskInServ.cellValue = (cellValue: any, dataItem?: AdwRow<ref_Crons>) => {
                return <CheckInServ dataItem={dataItem.dataItem} />
            };
            taskInServ.width = 160;
            columns.push(taskInServ);
            const grid = new VertexGrid<ref_Crons>({
                objectPrototype: ref_Crons,
                devMode: false,
                columns,
                order: ["Name", "CronType", "Schedule", "LastExecutionDate", "TaskParams", "taskInServ"],
                width: { CronType: 150, LastExecutionDate: 220 },
                vertexParams: {
                    Active: true,
                    properties: ["*", ...properties],
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return (
        <DefaultGrid
            objectPrototype={ref_Crons}
            grid={grid}
            gridProps={{
                commandCellArgs: {
                    additionalCommands: [{
                        icon: getIcon("play_arrow"),
                        function: (item: AdwRow<ref_Crons>, event) => {
                            Client.updateVertex(ref_Crons.name, { ...item.dataItem, executeNow: true });
                        }
                    }, {
                        icon: getIcon("stop_arrow"),
                        function: (item: AdwRow<ref_Crons>, event) => {
                            Client.updateVertex(ref_Crons.name, { ...item.dataItem, stopNow: true });
                        }
                    }],
                }
            }}
            defaultItem={defaultCron}
            dialogContainerStyle={{ overflow: "auto" }}
            dialogContent={(cron: ref_Crons) => (
                <CronsDialog cron={cron} defaultCron={defaultCron} days={days} />
            )}
            submitDialog={async (cron, mode) => {
                if (cron.Schedule?.Planned?.Date) {
                    cron.Schedule.Planned.Date = cron.Schedule.Planned.Date.toString() as any;
                }
                switch (mode) {
                    case eDialogMode.create:
                        return (await Client.createVertex(ref_Crons.name, cron)).data.results;
                    case eDialogMode.modify:
                        return (await Client.updateVertex(ref_Crons.name, cron)).data.results;
                    case eDialogMode.duplicate:
                        delete cron.LastExecutionDate;
                        return (await Client.createVertex(ref_Crons.name, cron)).data.results;
                }
            }}
        />
    );
};

export default Template(Crons);
