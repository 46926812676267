import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { ADWGridComponent, IsDebugMode, TooltipManager, TStateBase } from 'adwone-lib/index';
import { AdwRow, ADWColumn } from 'adwone-lib/index';
import { clone, compareObjects, firstOrDefaultWhere, GetSubElement, removeDiacritics, roundToNDecimals } from 'hub-lib/tools.bin';
import { GroupDescriptor } from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn as Column,
    GridFilterCell,
    GridDetailRowProps, GridCellProps, GridPageChangeEvent, GridHeaderCellProps, GridHeaderCell, GridExpandChangeEvent, GridProps
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
    CompositeFilterDescriptor,
    FilterDescriptor,
    filterBy,
} from '@progress/kendo-data-query';

import { GetCurrentLocale, Trad } from 'trad-lib';
import { ePropType, IsLink } from 'hub-lib/models/VertexProperty.bin';
import LockIcon from '@material-ui/icons/Lock';
import { GetCellClassName } from 'format-lib/index.bin';
import { getIcon } from 'adwone-lib/index';
import { ScrollMode } from '@progress/kendo-react-treelist/dist/npm/ScrollMode';
import { GridFooterCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridFooterCellProps';
import { useState } from 'react';
import { eKPIType, IsDate, IsPrice } from 'hub-lib/models/KPIsManager.bin';
import { SortDescriptor, MyOrderBy } from 'hub-lib/export/Tools.bin';
import { styleGridContainer, FooterRowSize, RowSize } from '../../../../styles/theme';
import { CustomButton } from '../../../ConfigurableComponents/CustomButton.bin';
import { GenericDialog, ConfirmContent } from '../../../ConfigurableComponents/GenericDialog.bin';
import { editCell } from '../../../crossedTable/CrossedTableTelerikTree.bin';
import { CommandCellArg, DefaultCellActions } from '../../DefaultCellActions';
import { CustomCheckbox } from '../CustomCheckbox.bin';
import Loader from '../../../layout/Loader';
import { Title } from '../../../Tools';
import { BaseCell } from './LightCell';
import { GetColumnType } from '../ColumnType.bin';
import { ConvertWidthToNumber, CustomWidth } from './Tools';
import { ConsoleDebug } from '../../../../utils/localstorage.bin';
import EventEmitter from 'events';
import { DataGrid } from './DataGrid';
import { RootState, store } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { eIndicateurType, Indicateur, IndicateurComputed, IndicateurToString } from 'adwone-engine/index.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';

const colSelectionSize = 38;
const colSize = 200;
const numberSize = 125;
let refreshTotalRowTimeout: any;

const typesWidth = {
    [ePropType.Date]: 155,
    [ePropType.Datetime]: 155,
    [ePropType.Integer]: numberSize,
    [ePropType.Double]: numberSize,
}

require('moment/locale/fr.js');
require('moment/locale/en-gb.js');
moment.locale(GetCurrentLocale());

export class TProps<TRow> {
    headerFiltersChanged?: (adapted: CompositeFilterDescriptor, base: CompositeFilterDescriptor) => void;
    onSortChanged?: (sort: SortDescriptor[]) => void;
    commandCellWidth?: number;
    onExport?: (type: 'formated' | 'csv') => void;
    onRef?: (ref?: ModeleGrid<TRow>) => void;
    grid: DataGrid<TRow>;
    sort?: SortDescriptor[];
    selectable?: boolean;
    selectionMode?: 'basic' | 'multiple';
    uneditable?: boolean;
    addButton?: boolean;
    DetailsComponent?: React.ComponentType<GridDetailRowProps>;
    groupable?: boolean;
    isCopyDisable?: boolean;
    isDeleteDisable?: boolean;
    title?: string | JSX.Element;
    selectionActive?: any;
    customAddButtons?: JSX.Element;
    customButtons?: JSX.Element;
    customMasseActions?: JSX.Element;
    customCommandCell?: any;
    customCommandCellFunction?: any;
    customRemoveInlineContent?: (item: AdwRow<TRow>) => JSX.Element;
    gridHeight?: number | string;
    deleteInline?: boolean;
    customAddText?: string;
    customAddClass?: string;
    customAddButtonIcon?: JSX.Element;
    commandCellArgs?: Partial<CommandCellArg<AdwRow<TRow>>>;
    isSelectable?: (row: AdwRow<TRow>) => boolean;
    pluriCustom?: boolean;
    MySelectionCell?: any;
    onEdit?: (row: AdwRow<TRow>) => void;
    onDelete?: (rows: AdwRow<TRow>[]) => void;
    onDuplicate?: (row: AdwRow<TRow>) => void;
    onAddNew?: () => void;
    selectionChange?: (rows: AdwRow<TRow>[]) => void;
    classNameProvider?: (col: ADWColumn<any>, row: AdwRow<any>) => string;
    scrollable?: ScrollMode;
    hideToolbar?: boolean;
    footer?: boolean;
    textLoader?: string;
    loaderHeight?: number | string;
    onRowInitialized?: (rows: AdwRow<TRow>[]) => void;
    loaderClassname?: string;
    autoColumnWidth?: boolean;
    // viewMode?: "Table" | "CrossedTable" | "Scheduler";
}

class TState<TRow> extends TStateBase<TRow> {
    originalRows: AdwRow<TRow>[];
    sort: SortDescriptor[];
    //filter: CompositeFilterDescriptor;
    actionsColumn: number = 0;
    /**
     * visibility of base remove dialog component
     */
    rmDialogVisible: boolean = false;

    // viewMode: "Table" | "CrossedTable" | "Scheduler" = "Table";
}
export class ModeleGrid<TRow> extends ADWGridComponent<
    TRow,
    TProps<TRow>,
    TState<TRow>
> {
    editField = 'inEdit';
    CommandCell: React.ComponentType<GridCellProps>;
    lastSelectedIndex: number = 0;

    static defaultProps = {
        classNameProvider: (col: ADWColumn<any>, row: AdwRow<any>) => row?.dataItem?.Active === false ? ' inactive_cell ' : ''
    };

    constructor(props: TProps<TRow>) {
        super(props);

        if (props.onAddNew || props.customAddButtons) this.addNew = props.onAddNew;
        if (props.onDelete || props.customMasseActions)
            this.removeGroup = props.onDelete;

        const paramsCommandCell = {
            edit: props.onEdit ?? this.enterEdit,
            add: this.add,
            discard: this.discard,
            update: this.update,
            cancel: this.cancel,
            copy: props.onDuplicate ?? this.copy,
            removeInline: this.removeInline,
            removeInlineContent: this.props.customRemoveInlineContent,
            deleteInline: this.props.deleteInline ?? false,
            editField: this.editField,
            isEditionDisable: this.props.uneditable,
            isCopyDisable: this.props.isCopyDisable ?? false,
            ...props.commandCellArgs
        }
        this.CommandCell = this.props.customCommandCell ? this.props.customCommandCell(paramsCommandCell, this.props.customCommandCellFunction) : DefaultCellActions(paramsCommandCell);
        props.onRef?.(this);
    }

    initializeState(): TState<TRow> {
        const state = new TState<TRow>();
        state.sort = this.props.sort ? [...this.props.sort] : [];
        return state;
    }

    moveKeys(keys: string[]) {
        this.state.rows.forEach((r) => {
            keys.forEach(k => {
                const value = GetSubElement(r.dataItem, k);
                if (value !== undefined) {
                    r[k] = value;
                }
            })
        });
    }

    /**
     * toggle the base remove dialog component
     */
    baseToggleRemoveDialog = () => {
        this.setState({ rmDialogVisible: !this.state.rmDialogVisible })
    }

    rowInitialized = async () => {
        this.setState({ originalRows: [...this.state.rows] }, () => this.props.onRowInitialized?.(this.state.originalRows));
    };

    enterEdit = async (dataItem: AdwRow<TRow>) => {
        const hasEditingItem = this.state.rows.some((p) => p.inEdit);
        if (!hasEditingItem) {
            this.setState({
                rows: await Promise.all(this.state.rows.map(async (item) => {
                    const copyRow = (item.id === dataItem.id ? { ...item, inEdit: true } : item);
                    await this.props.grid.ComputeCellValues([copyRow]);
                    return copyRow;
                })),
            });
        }
    };

    removeGroup = (rows: AdwRow<TRow>[]) => {

        this.setState(
            { rows: this.state.rows.filter(r => !rows.some(d => d.id == r.id)) },
            () => this.props.grid.delete(rows));
    };

    add = async (dataItem: AdwRow<TRow>) => {
        dataItem.id = uuidv4();
        let result = await this.props.grid.create(dataItem);
        if (result) {
            dataItem.inEdit = undefined;
            this.state.originalRows.push(dataItem)
            this.forceUpdate()
        } else {
            dataItem.id = undefined;
        }
    };
    copy = (item: AdwRow<TRow>) => {
        let newRow = new AdwRow<TRow>();
        newRow.dataItem = this.props.grid.createData();
        let key: any;
        let columns = this.props.grid.Columns;
        for (key in item) {
            let findCoresp = firstOrDefaultWhere(columns, (e) => e['title'] === key)
                ?.IsEditable();
            if (
                findCoresp === true ||
                (findCoresp === undefined && key !== 'dataItem' && key !== 'id')
            ) {
                newRow[key] = item[key];
            }
        }
        let newDataItem = { ...newRow, inEdit: true, Discontinued: false };
        this.setState({
            rows: [newDataItem, ...this.state.rows],
        });
    };
    discard = (dataItem: AdwRow<TRow>) => {
        const rows = [...this.state.rows];
        this.removeItem(rows, dataItem);

        this.setState({ rows });
    };

    update = async (dataItem: AdwRow<TRow>) => {
        if (dataItem.id) {
            const rows = [...this.state.rows];
            const updatedItem: any = { ...dataItem, inEdit: undefined };
            if (await this.props.grid.update(updatedItem)) {
                this.updateItem(rows, updatedItem);
                this.setState({ rows });
            }
        } else {
            this.add(dataItem)
        }
    };

    cancel = (dataItem: AdwRow<TRow>) => {
        const originalItem = this.state.originalRows.find(
            (p: AdwRow<TRow>) => p?.id === dataItem.id
        );
        const rows = this.state.rows.map((item) =>
            item.id === originalItem?.id ? originalItem : item
        );

        this.setState({ rows });
    };

    updateItem = (data: AdwRow<TRow>[], item: AdwRow<TRow>) => {
        let index = data.findIndex(
            (p) => p === item || (item.id !== undefined && p.id === item.id)
        );
        if (index >= 0) {
            data[index] = { ...item };
        }
    };

    itemChange = (event: any) => {
        const rows = this.state.rows.map((item) =>
            item.id === event.dataItem?.id
                ? { ...item, [event.field]: event.value }
                : item
        );

        this.setState({ rows });
    };

    addNew = () => {
        let newRow = new AdwRow<TRow>();
        newRow.dataItem = this.props.grid.createData();
        newRow = { ...newRow, ...newRow.dataItem };

        let newDataItem = { ...newRow, inEdit: true, Discontinued: false };

        this.setState({
            rows: [newDataItem, ...this.state.rows],
        });
    };

    cancelCurrentChanges = () => {
        this.setState({ rows: [...this.state.originalRows] });
    };

    removeItem(data: AdwRow<TRow>[], item: AdwRow<TRow>) {
        let index = data.findIndex(
            (p) => p === item || (item.id && p.id === item.id)
        );
        if (index >= 0) {
            data.splice(index, 1);
        }
    }
    removeInline = (item: AdwRow<TRow>) => {
        let newRows = this.state.rows.filter(e => e != item)
        this.setState({
            rows: newRows
        }, () => this.props.grid.delete([item]))
    }

    selectionChange = (event: any) => {

        if (IsDebugMode()) {
            console.log('selectionChange this.state.rows', this.state.rows);
            console.log('selectionChange event', event);
        }

        this.state.rows.forEach(e => {
            if (e.id && e.id === event?.dataItem?.id) {
                e.selected = !event.dataItem.selected;
            }
        });

        this.selectionChanged();
        eventSelectionChange.emit('selectionChange');
    };

    rowClick = (event: any) => {
        let last = this.lastSelectedIndex;
        const rows = [...this.state.rows];
        const current = rows.findIndex((dataItem) => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            rows.forEach((item) => (item.selected = false));
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            rows[i].selected = select;
        }
        this.setState({ rows });
    };

    expandChange = (event: any) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        this.forceUpdate();
    };
    _export: ExcelExport;

    private ComputeLeft = (idxCol: number) => {
        let { grid } = this.props;
        let calc = colSelectionSize;
        for (let idx = 0; idx < grid.Columns.length; idx++) {
            const col = grid.Columns[idx];
            if (idx === idxCol)
                return calc;
            if (col.frozen)
                calc += ConvertWidthToNumber(this.getColumnWidth(col));
        }
        return calc;
    }

    private ComputeRight = (idxCol: number) => {
        let { grid } = this.props;
        let calc = 100;

        for (let idx = grid.Columns.length - 1; idx >= 0; idx--) {
            const col = grid.Columns[idx];

            if (idx === idxCol)
                return calc;

            if (col.frozen)
                calc += (Number.isNaN(col.width) ? colSize : Number(col.width))
        }

        return calc;
    }

    filterCell = (props: any) => {
        return <div className={'container-header-sticky'}><GridFilterCell {...props} /></div>
    }

    /**
     * selection cell (combobox)
     * @param props
     */
    MySelectionCell = (props: GridCellProps) => {

        if (this.props.isSelectable)
            return <CustomCheckbox props={{
                ...props,
                selectionChange: this.selectionChange,
                grid: this.props.grid,
                disabled: !this.props.isSelectable(props.dataItem)
            }} />

        if (!this.props.selectionActive || this.props.selectionActive(props)) {
            return <CustomCheckbox props={{ ...props, selectionChange: this.selectionChange, grid: this.props.grid }} />;

        } else {
            props.dataItem.Selectable = false;
            return <LockIcon style={{ color: '#DDDDDD' }} />;
        }
    };



    filterCellRight = (props: any) => {
        return <div className={'add-border-right container-header-sticky'}><GridFilterCell {...props} /></div>
    }

    filterCellLeft = (props: any) => {
        return <div className={'add-border-left container-header-sticky'}><GridFilterCell {...props} /></div>
    }

    filterCellLeftEmpty = () => {
        return <div className={'add-border-left container-header-sticky'}></div>
    }

    filterCellBoth = (props: any) => {
        return <div className={'add-border-right add-border-left container-header-sticky'}><GridFilterCell {...props} /></div>
    }

    getColumnWidth = (c: ADWColumn<any>) => {
        const customWidths = CustomWidth.getCustomWidths();
        let width: string | number = customWidths[c.field] ?? c.width ?? typesWidth[c.dataType] ?? colSize;
        if (typeof width == 'number')
            width = `${width}px`;
        return width;
    }

    createColumnComponent = (c: ADWColumn<any>, idx: number) => {
        let className = '';

        const { grid } = this.props;

        let filterCell = this.filterCell;

        const definition = IndicateurToDefinition(c.baseColumn);

        // const rows = filterBy(MyOrderBy(this.state.rows, this.getSort()), this.getFilter())

        if (c.frozen) {
            if (idx > 0 && !grid.Columns?.[idx - 1]?.frozen) {
                className += ' add-border-left ';
                filterCell = this.filterCellLeft;
            }

            if (!grid.Columns?.[idx + 1]?.frozen) {
                className += ' add-border-right ';
                if (filterCell === this.filterCellLeft) filterCell = this.filterCellBoth;
                else filterCell = this.filterCellRight;
            }
        }

        const currentFrozLeft = this.ComputeLeft(idx);
        const currentFrozRight = this.ComputeRight(idx);
        const locked: any = c.frozen !== undefined;
        const isNumber = [ePropType.Integer, ePropType.Double].includes(c.dataType)
            || IsPrice(c.baseColumn?.valueType);

        const createCell = (_className: string = "") => BaseCell({
            column: c,
            indicateur: c.baseColumn,
            vGrid: this.props.grid,
            cellContent: c.cellContent,
            // cellValue: c.cellValue,
            frozen: c.frozen,
            frozenLeftPx: currentFrozLeft,
            frozenRightPx: currentFrozRight,
            className: `${_className} ${GetCellClassName(c.dataType)} column_${c.baseColumn?.field ?? c.bindingPath?.toString()} ${className} `,
            classNameProvider: (row) => this.props?.classNameProvider(c, row),
            grid: this
        });

        const cell = createCell();

        // console.log(`[createColumnComponent]`, this.state.totalRow);
        const footerCell = (props: GridFooterCellProps) =>
            createCell(`footerCellList ${this.state.totalRowLoading ? 'footerCellLoading' : ''}`)({ ...props, dataIndex: 0, dataItem: this.state.totalRow, field: c.field, id: '0', isSelected: false });
        const titleHeader = c?.titleHeader ?? c.title;

        return <Column
            {...(c.indicateurHash && { id: c.indicateurHash })}
            key={`col:${c.title}:${c.bindingPath?.toString()}`}
            resizable={true}
            locked={locked}
            headerCell={(props) => <HeaderColumn title={titleHeader} tooltype={definition} headerProps={{ ...props, title: titleHeader }} />}
            headerClassName={className}
            filterCell={filterCell}
            field={c.field}
            title={c.title}
            width={this.getColumnWidth(c)}
            filter={GetColumnType(c.dataType)}
            editor={GetColumnType(c.dataType)}
            footerCell={this.props.footer && isNumber && footerCell}
            cell={cell}
        />
    }

    /**
     * Click on header selection all
     * @param event
     */
    headerSelectionChange = (event: any) => {
        const checked = event.syntheticEvent.target.checked;

        // filerBy to only get displayed rows
        let filteredrows = filterBy(this.state.rows, this.getFilter());

        /** eventually get selectable rows */
        filteredrows = this.getSelectable(filteredrows);

        filteredrows.forEach(r => r.selected = checked);

        this.forceUpdate();
        this.selectionChanged();
    };

    /**
     * eventually get selectable rows
     * @param rows
     */
    getSelectable = (rows: AdwRow<TRow>[]) => {
        let { isSelectable } = this.props;
        if (isSelectable)
            rows = rows.filter(r => isSelectable(r));
        return rows
    }

    selectRow = (row: AdwRow<TRow>) => {

        const { selectable, selectionMode } = this.props;

        if (!selectable)
            return;

        switch (selectionMode) {
            case 'multiple':
                row.selected = !row.selected;
                break;
            case 'basic':
            default:
                this.state.rows.forEach(r => r.selected = false);
                row.selected = true;
                break;
        }

        /** Doit appeler forceUpdate car selectionChanged ne refresh pas systématiquement le render */
        this.forceUpdate();
        this.selectionChanged();
    }

    /**
     * must be called when selection changed
     */
    selectionChanged = () => {
        // check if the behaviour is overriden
        let { selectionChange } = this.props;
        if (selectionChange) {
            const filteredrows = filterBy(this.state.rows, this.getFilter());
            selectionChange(filteredrows.filter((r) => r.selected));
        }
    }

    getCommandCellWidth = () => {
        const { customCommandCell, pluriCustom, commandCellArgs, deleteInline, commandCellWidth } = this.props;
        if (customCommandCell && !pluriCustom)
            return 50;
        if (commandCellWidth)
            return commandCellWidth;

        const additionalCommands = commandCellArgs?.additionalCommands;
        const isEditable = !this.props.uneditable && (commandCellArgs?.isEditable !== false);
        const isCopyable = !this.props.isCopyDisable && !commandCellArgs?.isCopyDisable;
        const isRemoveable = (commandCellArgs?.deleteInline);

        let width = (additionalCommands?.length ?? 0) * editCell;
        if (isEditable) width += editCell;
        if (isCopyable) width += editCell;
        if (isRemoveable) width += editCell;

        return width;
    }

    DrawComponent: () => JSX.Element = () => {

        const { grid, textLoader, title, loaderClassname } = this.props;
        const { rows, loading } = this.state;
        const containerHeight = this.props.gridHeight ?? styleGridContainer.messages.height;

        const loader = <div className={`loader-container ${loaderClassname ?? ''}`} style={{ height: this.props.loaderHeight ?? containerHeight }}>
            <Loader key={`loading-${Date.now()}`} text={textLoader} time></Loader>
        </div>

        console.log(`[DRAWCOMPONENT]`, grid.Columns?.length, rows);

        if (!grid.Columns?.length || !rows) return loader;

        /** filter and order data to display  */
        const data: AdwRow<TRow>[] = MyOrderBy(filterBy(rows, this.getFilter()), this.getSort());

        //console.log(`[DRAWCOMPONENT]`, this.getFilter(), rows)

        /** any row selected in screen, /!\ may have row selected that are filtered */
        const hasSelectedItem = data.some((p) => p.selected);
        const hasEditingItem = data.some((p) => p.inEdit);
        return <>
            {loading && loader}
            {<ExcelExport ref={(exporter) => (this._export = exporter)}>
                {/* toolbar */}
                {!this.props.hideToolbar &&
                    <div className='clearfix custom-toolbar-adwtelerikgrid'>

                        <div className="clearfix">
                            {/** Title */}
                            {title && <div style={{ float: 'left', display: 'block' }} className="clearfix">
                                <Title>{title}</Title>
                            </div>}

                            {/** Options: delete, exports etc ... */}
                            <div style={{ float: 'right', minWidth: 250 }}>
                                <div style={{ display: 'inline' }} >

                                    {/** OPTIONAL CUSTOM ADD BUTTONS */}
                                    {!hasSelectedItem &&
                                        this.props.customAddButtons}

                                    {/** DEFAULT ADD BUTTON */}
                                    {!hasSelectedItem && !this.props.customAddButtons && this.props.addButton && (
                                        <CustomButton
                                            style={{ float: 'right' }}
                                            Label={this.props.customAddText ?? Trad('add')}
                                            className={this.props.customAddClass ?? 'custom_btn_primary'}
                                            startIcon={this.props.customAddButtonIcon ?? getIcon('plus')}
                                            disabled={hasEditingItem}
                                            onClick={this.addNew} />
                                    )}

                                    {/** OPTIONAL CUSTOM BUTTONS */}
                                    {!hasSelectedItem &&
                                        this.props.customButtons}

                                    {/** DEFAULT REMOVE */}
                                    {hasSelectedItem && !this.props.customMasseActions && this.props.addButton && (
                                        <>
                                            {/** default remove button */}
                                            <CustomButton
                                                style={{ float: 'right', marginRight: 10 }}
                                                Label={Trad('remove')}
                                                disabled={this.props.isDeleteDisable ?? false}
                                                className="custom_btn_danger"
                                                startIcon={getIcon('delete')}
                                                onClick={this.baseToggleRemoveDialog} />
                                        </>
                                    )}

                                    {/** OPTIONAL CUSTOM MASS ACTIONS */}
                                    {hasSelectedItem &&
                                        this.props.customMasseActions}

                                </div>
                            </div>
                        </div>

                        {/** Potential toolbar */}
                        <div>{this.props.children}</div>

                    </div>
                }
                {/** default remove dialog */}
                {this.state.rmDialogVisible && this.DefaultRemoveDialog(data)}
                <div className="with-borders">
                    {!loading && <PagedGrid
                        grid={this}
                        data={data} />}
                </div>
            </ExcelExport>}
        </>
    };

    /**
     * default remove dialog component
     * @param data grid data
     */
    private DefaultRemoveDialog(data: AdwRow<TRow>[]) {

        let selectedRows = data.filter(d => d.selected);

        return <GenericDialog
            open={this.state.rmDialogVisible}
            dialogTitle={Trad('confirmation')}
            handleClose={this.baseToggleRemoveDialog}
            submitAction={() => {
                this.removeGroup(selectedRows);
                this.baseToggleRemoveDialog();
            }}
            submitClass="custom_btn_danger"
            submitTitle={Trad('yes')}
            startIcon={getIcon('delete')}
            actions={true}>
            <p>{ConfirmContent(selectedRows)}</p>
        </GenericDialog>
    }

    correctSort(sort: SortDescriptor[]) {
        sort?.forEach((s) => {
            let col = firstOrDefaultWhere(
                this.props.grid.Columns,
                (c) => c.field === s.field
            );
            if (IsLink(col?.dataType)) {
                s.field = `${s.field}.Formated`;
            } else {
                s.field = `${s.field}.Value`;
            }
        });

        return sort;
    }

    correctFilter(filters: Array<FilterDescriptor | CompositeFilterDescriptor>) {
        const { grid } = this.props;
        const adapted = [];
        filters?.forEach?.((f: any) => {
            let col: ADWColumn<TRow> = null;
            if (f.field) {
                col = grid.Columns.find(c => c.field === f.field);
                if (!col) return;
                if (IsLink(col?.dataType) || col?.dataType == ePropType.String) {
                    f.field = `${f.field}.Formated`;
                } else {
                    f.field = `${f.field}.Value`;
                }
                f["indicateurHash"] = col.indicateurHash;
            }
            if (f.value && col?.dataType != ePropType.Boolean)
                f.value = removeDiacritics(f.value);

            if (col?.baseColumn?.valueType == eKPIType.Percent)
                f.value = roundToNDecimals(f.value / 100, 4);

            adapted.push({
                // field: s.field,
                field: (e) => {
                    const value = e[f.field];
                    // if value is a date, we take the same date at 00:00:00
                    if (value instanceof Date)
                        return moment(value).startOf('day').toDate();
                    if (typeof value === 'string')
                        return removeDiacritics(value);
                    return value;
                },
                indicateurHash: f.indicateurHash,
                fieldStr: f.field,
                operator: f.operator,
                value: f.value
            })
        });
        return adapted;
    }

    private getSort() {
        let copy = (this.state.sort ?? []).map((s) => {
            return { ...s };
        });
        return this.correctSort(copy);
    }

    private getFilter() {
        const filter = store.getState().grid.gridFilterBase;
        if (!filter) return undefined;

        const res = this.correctFilter(clone(filter?.filters));
        const copyTotal = { ...filter, filters: res };

        if (IsDebugMode()) {
            console.log('Grid filters:')
            console.log(copyTotal)
        }

        return copyTotal;
    }
}

let timeout = null;
type PagedGridProps = {
    grid: ModeleGrid<any>,
    // selectableRows: any[],
    data: any[]
}

const eventSelectionChange = new EventEmitter();
function OptimizedGrid(props: GridProps & { gridRef: any }) {
    const { scrollable, gridRef } = props;
    const [skip, setSkip] = useState(0);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const time8278 = new Date().getTime();
    let dataSlice = props.data;
    if (scrollable == 'virtual') {
        if (skip > (props.data as any[]).length)
            setSkip(0);
        dataSlice = (props.data as any[]).slice(skip, skip + 50);
    }
    const _time8278 = new Date().getTime();
    ConsoleDebug(`[OptimizedGrid] slice ${_time8278 - time8278}ms`);

    React.useEffect(() => {
        const update = () => { forceUpdate(); };
        eventSelectionChange.on('selectionChange', update);
        return () => { eventSelectionChange.removeListener('selectionChange', update); }
    }, [gridRef?.current]);

    return <Grid
        ref={gridRef}
        {...props}
        {...(scrollable == 'virtual' ? {
            onPageChange: (event: GridPageChangeEvent) => {
                if (skip != event.page.skip)
                    setSkip(isNaN(event.page.skip) ? 0 : event.page.skip);
            },
            skip: skip,
            pageSize: 50
        } : {})}
        data={dataSlice}>
        {props.children}
    </Grid>
}


function PagedGrid({ grid, data }: PagedGridProps) {

    const filter = useSelector((state: RootState) => state.grid.gridFilterBase, compareObjects);
    const [collapsedState, setCollapsedState] = React.useState<string[]>([]);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const gridRef = React.useRef<any>();
    // const [height, setHeight] = useState(0);
    const [groups, setGroups] = useState<GroupDescriptor[]>([]);

    let scrollable: ScrollMode = grid.props.scrollable ?? 'virtual';
    if (grid.props.groupable)
        scrollable = 'scrollable';

    const container: any = React.useRef();
    React.useEffect(() => {
        if (gridRef?.current) {
            const rowIndex = data.findIndex((e) => e.selected);
            if (rowIndex >= 0) {
                setTimeout(() => {
                    const offsetHeight = gridRef.current?.vs?.table?.children?.[0]?.children?.[0]?.offsetHeight
                    if (offsetHeight) {
                        const containerHeight = gridRef.current.vs.containerRef.current.offsetHeight;
                        const size = Math.floor(containerHeight / offsetHeight);
                        if (data.length - rowIndex < size) {
                            console.log('[rowIndex] GOTO', data.length - size)
                            gridRef.current.scrollIntoView({ rowIndex: data.length - size });
                        } else {
                            console.log('[rowIndex] GOTO rowIndex', rowIndex)
                            gridRef.current.scrollIntoView({ rowIndex });
                        }
                    }
                }, 200);
            }
        }
    }, [gridRef?.current]);

    const baseHeight = grid.props.gridHeight ?? styleGridContainer.messages.height;
    const computedHeight = typeof baseHeight == 'number'
        ? `calc(${baseHeight}px - ${FooterRowSize}px)`
        : baseHeight;

    // if (grid.props.groupable && groups?.length > 0) {
    //   const newDataState = groupBy(dataSlice, groups.map(g => ({ field: GetHashCode(g.field)?.toString() + '_formated' }))) as GroupResult[];

    //   newDataState.forEach(d => {
    //     d.value = `(${d.items.length}) ${d.value}`;
    //   });

    //   setGroupIds({ data: newDataState, group: groups });
    //   dataSlice = setExpandedState({
    //     data: newDataState,
    //     collapsedIds: collapsedState,
    //   });
    // }

    const onExpandChange = React.useCallback(
        (event: GridExpandChangeEvent) => {
            const item = event.dataItem;

            if (item.groupId) {
                const newCollapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(newCollapsedIds);
            }
        },
        [collapsedState]
    );

    if (grid?.state?.sort) {
        const copySort = clone(grid.state.sort);
        grid.moveKeys([...copySort.map(s => s.field + '.Value'), ...copySort.map(s => s.field + '.Formated')]);
    }

    if (filter?.filters?.length > 0)
        grid.moveKeys([...filter?.filters.map((s: any) => s.field + '.Value'), ...filter?.filters.map((s: any) => s.field + '.Formated')]);

    const selectableRows = grid.getSelectable(data);

    return <div style={{ height: baseHeight }} ref={container}>
        <div>
            <OptimizedGrid
                style={{ width: '100%', height: computedHeight }}
                resizable={true}
                onColumnResize={e => {
                    const customWidths = CustomWidth.getCustomWidths();
                    e.columns?.forEach((c, i) => {
                        const col = grid.props.grid.Columns[i - 1];
                        if (!col) return;
                        col.width = c.width;
                        customWidths[col.field] = col.width;
                    });

                    CustomWidth.setCustomWidths(customWidths);

                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        forceUpdate();
                    }, 5);
                }}

                rowHeight={RowSize}
                scrollable={scrollable}
                data={data}
                total={data.length}

                // {...(scrollable == 'virtual' ? {
                //   onPageChange: (event: GridPageChangeEvent) => {
                //     if (skip != event.page.skip)
                //       setSkip(event.page.skip);
                //   },
                //   skip: skip,
                //   pageSize: 50
                // } : {})}

                gridRef={gridRef}

                onItemChange={grid.itemChange}
                editField={grid.editField}
                selectedField="selected"
                onSelectionChange={grid.selectionChange}
                expandField="expanded"
                // onRowClick={this.rowClick}
                onHeaderSelectionChange={grid.headerSelectionChange}
                className="grid_table"

                /** Row details */
                {...(grid.props.DetailsComponent ? {
                    detail: grid.props.DetailsComponent,
                    onExpandChange: grid.expandChange
                } : {})}

                /** Grouping */
                {...(grid.props.groupable ? {
                    groupable: true,
                    onGroupChange: e => {
                        setGroups(e.group);
                        // console.log('onGroupChange', e)
                    },
                    group: groups,
                    onExpandChange: onExpandChange
                } : {})}

                sort={[...(grid.state.sort ?? [])]}
                sortable={{
                    mode: 'multiple',
                }}
                onSortChange={async (e) => {
                    const copySort = clone(e.sort);
                    grid.moveKeys([...copySort.map(s => s.field + '.Value'), ...copySort.map(s => s.field + '.Formated')]);
                    grid.setState({ sort: [...clone(e.sort)] }, () => {
                        grid.props.onSortChanged?.(grid.correctSort(e.sort));
                        grid.selectionChanged();
                    })
                }}
                reorderable
                filterable
                filter={filter}
                onFilterChange={async (e) => {

                    if (e.filter?.filters?.length > 0)
                        grid.moveKeys([...e.filter.filters.map((s: any) => s.field + '.Value'), ...e.filter.filters.map((s: any) => s.field + '.Formated')]);

                    const adaptedFilters = grid.correctFilter(clone(e.filter?.filters ?? [])) as any
                    grid.props.headerFiltersChanged?.({ logic: "and", filters: adaptedFilters }, e.filter);
                    grid.selectionChanged();
                    //grid.forceUpdate();

                    grid.setState({ totalRowLoading: true },
                        () => {
                            clearTimeout(refreshTotalRowTimeout);
                            refreshTotalRowTimeout = setTimeout(async () => {
                                try {
                                    const newTotalRow = await grid.props.grid.UpdateTotalRowsFromServer();
                                    grid.setState({
                                        totalRowLoading: false,
                                        totalRow: { ...grid.state.totalRow, dataItem: newTotalRow }
                                    })
                                } catch (e) {
                                    // can be cancel request
                                    console.error(e);
                                }
                            }, 500);
                        })
                }}
                onColumnReorder={e => {

                    // e.columns[0].id

                    grid.props.grid.onReorder(e.columns.sort((a, b) => a.orderIndex - b.orderIndex).map(c => c.id));
                }}>
                <Column
                    locked
                    headerClassName='k-grid-myselection-cell'
                    orderIndex={0}
                    field={grid.props.selectable ? 'selected' : ''}
                    width={colSelectionSize}
                    filterable={false}
                    reorderable={false}
                    resizable={false}
                    cell={(props) => props.rowType == 'groupHeader' ? <></> : <td className="k-grid-content-sticky k-grid-myselection-cell">{grid.props.selectable ? grid.MySelectionCell(props) : <></>}</td>}
                    headerSelectionValue={selectableRows.length > 0 && !selectableRows.some(d => !d.selected)}
                />

                {grid.props.grid.Columns.map(grid.createColumnComponent)}
                {!grid.props.uneditable && <Column
                    locked
                    resizable={false}
                    key={grid.state.actionsColumn}
                    filterCell={grid.filterCellLeftEmpty}
                    headerClassName={'add-border-left'}
                    cell={grid.CommandCell}
                    width={grid.getCommandCellWidth()}
                    filterable={true}
                    editable={false}
                    reorderable={false}
                />}
            </OptimizedGrid>
        </div>
        <FooterCellTotalElement count={data.length} />
    </div >
}

type HeaderColumn = { title: string, tooltype: string, headerProps: GridHeaderCellProps }
function HeaderColumn({ title, tooltype, headerProps }) {
    return <span onMouseOver={e => TooltipManager.Push({ target: e.target, text: tooltype })}><GridHeaderCell {...headerProps} /></span>
}

type FooterCellTotalElementProps = { count: number }
export function FooterCellTotalElement({ count }: FooterCellTotalElementProps) {
    return <div className='FooterCellTotalElement' style={{ height: FooterRowSize }}>
        <span>{`${count} ${count <= 1 ? Trad('element') : Trad('elements')}`}</span>
    </div>
}

export function IndicateurToDefinition(indicateur: Indicateur | string): string {
    const tradKey = typeof indicateur === "string"
        ? indicateur
        : store.getState().columsConfigurations.indicateurOptions[ref_Messages.name]
            ?.find(i => IndicateurToString(i.indicateur) === IndicateurToString(indicateur))?.tradKey;

    const translatedDefinition = tradKey && Trad(`${tradKey}_definition`);
    if (translatedDefinition && translatedDefinition !== `${tradKey}_definition`) {
        return translatedDefinition;
    }

    if (typeof indicateur !== "string") {
        if (indicateur.type === eIndicateurType.computed) {
            const { indicateurs, operator } = indicateur as IndicateurComputed;
            return indicateurs.map(i => i.name).join(` ${operator} `);
        }

        return indicateur.name;
    }

    return Trad(indicateur);
}

