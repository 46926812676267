import { MediaSpecsCrons, MediaSpecsCronsParams } from "hub-lib/dto/admin/ref_Crons.bin";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";

type MediaSpecsConfigurationProps = {
    cron: MediaSpecsCrons,
    onChange: (cron: MediaSpecsCrons) => void
}

export function MediaSpecsConfiguration({ cron, onChange }: MediaSpecsConfigurationProps) {
    return (
        <div>
            <h1>Media Specs Configuration</h1>
            <CustomButton
                Label={"Import defaut conf"}
                onClick={() => {
                    cron.TaskParams = new MediaSpecsCronsParams();
                    onChange(cron)
                }} />

            <div className="adw-row" />
        </div>
    );
}