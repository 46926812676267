import * as React from 'react'
import { ref_ReleaseVersion } from 'hub-lib/dto/admin/ref_ReleaseVersion.bin'
import { Grid, TextField } from '@material-ui/core';
import { Trad, TradProp } from 'trad-lib';
import { SimpleDatePicker } from '../../ConfigurableComponents/SimpleDatepicker.bin';
import { propertyOf } from 'hub-lib/tools.bin';
import { ContainerComponent } from '../Generic/ContainerComponent';
import { ref_Attachments, ref_Files } from 'hub-lib/dto/client/ref_Attachments.bin';
import { Client } from 'hub-lib/client/client.bin';
import { FilesPicker } from '../Generic/FilesPicker.bin';


export function VersionMetrics(props) {
    const [state, setState] = React.useState<ref_ReleaseVersion>();
    const [imgSrc, setImgSrc] = React.useState("");
    const [imgAttachment, setImgAttachment] = React.useState<ref_Attachments>();
    const [noteAttachment, setNoteAttachment] = React.useState<ref_Attachments>();
    const date = props.version ? new Date(props.version.Date) : new Date();

    React.useEffect(() => {
        setState({ ...state, Date: date })
        if (props.version)
            setState(props.version)
        setVersionImage();
        setVersionNote();
    }, [props.version])

    props.handleData(state);

    const setVersionImage = async () => {
        if (props.version?.Image) {
            try {
                const [Image] = await Client.searchVertexTyped(ref_Attachments, { '@rid': props.version?.Image });
                const img = await Client.getURLAttachmentFile({ _id: Image?.Content })
                setImgSrc(img)
                setImgAttachment(Image);
            } catch (error) {
                console.log(error)
            }
        }
    }

    const setVersionNote = async () => {
        if (props.version?.Note) {
            try {
                const [Note] = await Client.searchVertexTyped(ref_Attachments, { '@rid': props.version?.Note });
                setNoteAttachment(Note);
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <Grid container className="block-container" >
            <TextField
                label={TradProp(`${propertyOf<ref_ReleaseVersion>("Name")}`, ref_ReleaseVersion)}
                autoFocus
                variant="outlined"
                style={{ width: '100%', marginBottom: 12 }}
                defaultValue={props.version?.Name}
                onChange={(e) => setState({ ...state, Name: e.target.value })}
            />
            <div style={{ overflow: "visible", width: "100%", marginBottom: 12 }}>
                <div style={{ width: "100%", float: "left" }}>
                    <SimpleDatePicker label={TradProp(`${propertyOf<ref_ReleaseVersion>("Date")}`, ref_ReleaseVersion)} defaultValue={date} onChange={e => {
                        setState({ ...state, Date: e.target.value })
                    }} />
                </div>
            </div>

            <div style={{ marginBottom: 12, width: '100%' }}>
                <ContainerComponent title={`${Trad('version_image')} | ${Trad("files")}`} className="auto-height no-padding no-margin">
                    <div style={{ width: '98.75%' }} className="upload-image">
                        <div className="file-picker">
                            <FilesPicker rid={props.version?.attachments} extensions={["png", "jpg", "jpeg"]} attachments={imgAttachment ? [imgAttachment as ref_Files] : []} type='name'
                                onChange={(e) => {
                                    setState({ ...state, Image: e['@rid'] })
                                }}
                                onRemove={() => {
                                    setState({ ...state, Image: null });
                                    setImgSrc("");
                                }}
                            />
                        </div>
                        <div style={{ margin: 12 }} className="image-preview">
                            {
                                imgSrc ?
                                    <div style={{ objectFit: 'cover', height: '30vh' }}>
                                        <img style={{ maxWidth: 'inherit', maxHeight: 'inherit', width: 'inherit', height: 'inherit' }} src={imgSrc} alt="image" />
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </ContainerComponent>
            </div>
            <div style={{ marginBottom: 12, width: '100%' }}>
                <ContainerComponent title={`${Trad('pdf_file')} | ${Trad("files")}`} className="auto-height no-padding no-margin">
                    <FilesPicker rid={props.version?.Note} extensions={['.pdf']} attachments={noteAttachment ? [noteAttachment as ref_Files] : []} type='name'
                        onChange={(e) => {
                            setState({ ...state, Note: e['@rid'] })
                        }}
                        onRemove={() => {
                            setState({ ...state, Note: null })
                        }}
                    />
                </ContainerComponent>
            </div>
        </Grid>
    )
}