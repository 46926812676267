import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { Indicateur, IndicateurJoin, eIndicateurType, propertyOption, IndicateurInfo, IndicateurComputed, IndicateurBase, formaterTrad, isDirectionPercent, formatMomentPeriodicity, formaterMomentBase, IndicateurLink } from "./index.bin";
import { CellValue, Row } from "./types.bin";
import { MetaDataProperty } from "hub-lib/models/types.bin";
import { src_AdwOne } from "hub-lib/models/orientdb/src_AdwOne.bin";
import { clone, Dictionary, distinct, IsIDDB, JoinElements, memoize, memoizeAsync, memoizeAsyncBase, propertyOf, ReleaseEventLoop, splitBlocks, splitBlocksFor, toArray, toDictionary } from "hub-lib/tools.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { Format, GetKPITemplate, GetPropTemplate, GetPropertyTypeTemplate, HTMLToString, Sort, propsNeededForFormat, GetCellTemplate } from "format-lib/index.bin";
import { eKPIType, IsPrice, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { DataProvider } from "hub-lib/provider";
import { FormatPrice, GetCurrentLocale, Trad } from "trad-lib";
import { getUniqueWeekYears } from "tools-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Periodicity } from "hub-lib/models/orientdb/ref_Periodicity.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { CellValue as CellValueEngine } from "adwone-engine/types.bin";
import { LabelBuilder } from "hub-lib/dto/client/ref_SchedulerConfigurations.bin";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import moment from "moment";

export interface IDated {
    Start: Date;
    End: Date;
}

export interface IDatedData<T> extends IDated {
    Data: T[];
}

export function isDate(obj) {
    return obj instanceof Date;
}

const getMetadataMemo = memoizeAsync((documentType: string) => DataProvider.getMetadata(documentType));

export function getMinMaxDate<T>(data: T[], dateField: keyof T, mode: 'min' | 'max'): Date {
    let dateTime: number = mode === 'min' ? Infinity : -Infinity;
    const dataLength = data.length;
    for (let i = 0; i < dataLength; i++) {
        const valToCompare = data[i][dateField] ?? 0;
        const currentDate = (isDate(valToCompare) ? <Date><unknown>valToCompare : new Date(<number><unknown>valToCompare)).getTime();

        if ((mode === 'min' && currentDate < dateTime) || (mode === 'max' && currentDate > dateTime)) {
            dateTime = currentDate;
        }
    }
    return new Date(dateTime);
}

export async function BuildProperties(documentType: string, indicateurs: Indicateur[], basesProperties: string[] = []) {

    if ([ref_Messages.name, ref_Campaigns.name].includes(documentType)) {
        const allIndicateurs = await AggregatorFactory.GetInstance().Get(documentType).Provide();
        const addIndicateurFromField = (field: string) => {
            const ind = allIndicateurs.find(i => i.indicateur.field == field)?.indicateur;
            if (ind && !indicateurs.some(i => i.field == field))
                indicateurs.push(ind);
        }
        //addIndicateurFromField("Currency");
        //addIndicateurFromField("ReturnedCurrency");
        addIndicateurFromField("Media");
        addIndicateurFromField("Status");
    }

    const properties = [
        ...basesProperties,
        ...EngineTools.BuildPropertiesFromIndicateurs(indicateurs)];

    const aliases = basesProperties.map(p => p.split(' as ')?.[1]).filter(Boolean);
    const subPropertiesToRemove = properties
        .filter(p => p.includes('.') && !p.includes(' as ') && !p.includes('.first('))
        .map(p => ({ base: p.split('.')[0], toRemove: p })).filter(p => properties.includes(p.base));
    return properties
        .filter(p => !aliases.includes(p))
        .filter(p => !subPropertiesToRemove.some(sp => sp.toRemove == p));
}

export function getMinDate<T>(data: T[], dateField: keyof T): Date {
    return getMinMaxDate(data, dateField, 'min');
}

export function getMaxDate<T>(data: T[], dateField: keyof T): Date {
    return getMinMaxDate(data, dateField, 'max');
}

export const FormatCellsMemoized = memoizeAsyncBase((documentType: string, indicateur: Indicateur, cells: CellValue[]) => {
    const ress = EngineTools.FormatCells(documentType, indicateur, cells);
    return ress;
})

type IDatedDataExtended<T> = (IDatedData<T> & { Weeks: string[] })

export class EngineTools {

    static GetCurrencies = async () => {
        return toDictionary(await DataProvider.searchMemoized<ref_Currencies>(ref_Currencies.name, { admin: true }), c => c['@rid']);
    }

    static groupElementsByDate<T>(elements: T[]): IDatedData<T>[] {

        const groups: IDatedData<T>[] = [];

        // convert string dates to Date
        elements.forEach(e => {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });

        // order elements by start date
        elements = [...elements].sort((a, b) => {
            const aStart = a['Start'] as Date;
            const bStart = b['Start'] as Date;
            return (aStart?.getTime?.() ?? 0) - (bStart?.getTime?.() ?? 0);
        });

        let currentGroup: IDatedData<T> = null;
        for (const element of elements) {
            if (!currentGroup) {
                currentGroup = {
                    Start: element['Start'],
                    End: element['End'],
                    Data: [element]
                };
                groups.push(currentGroup);
            } else {
                const currentGroupEnd = currentGroup.End as Date;
                const elementStart = element['Start'] as Date;
                if (currentGroupEnd && elementStart) {
                    if (elementStart?.getTime?.() <= currentGroupEnd?.getTime?.()) {
                        currentGroup.Data.push(element);
                        currentGroup.End = getMaxDate(currentGroup.Data, <any>"End");
                    }
                    else {
                        currentGroup = {
                            Start: element['Start'],
                            End: element['End'],
                            Data: [element]
                        };
                        groups.push(currentGroup);
                    }
                } else {
                    //
                }
            }
        }
        return groups;
    }

    static groupElementsByWeek<T>(elements: T[]): IDatedDataExtended<T>[] {


        const groups: (IDatedDataExtended<T> & { Weeks: string[] })[] = [];

        // convert string dates to Date
        elements.forEach(e => {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });

        // order elements by start date
        elements = [...elements].sort((a, b) => {
            const aStart = a['Start'] as Date;
            const bStart = b['Start'] as Date;
            return (aStart?.getTime?.() ?? 0) - (bStart?.getTime?.() ?? 0);
        });

        let currentGroup: IDatedDataExtended<T> = null;
        for (const element of elements) {
            if (!currentGroup) {
                currentGroup = {
                    Start: element['Start'],
                    End: element['End'],
                    Weeks: getUniqueWeekYears(element['Start'] as Date, element['End'] as Date),
                    Data: [element]
                };
                groups.push(currentGroup);
            } else {
                const currentGroupEnd = currentGroup.End as Date;
                const elementStart = element['Start'] as Date;
                if (currentGroupEnd && elementStart && getUniqueWeekYears(elementStart, elementStart).some(w => currentGroup.Weeks.includes(w))) {
                    currentGroup.Data.push(element);
                    currentGroup.End = getMaxDate(currentGroup.Data, <any>"End");
                    currentGroup.Weeks = getUniqueWeekYears(currentGroup.Start, currentGroup.End);
                } else {
                    currentGroup = {
                        Start: element['Start'],
                        End: element['End'],
                        Weeks: getUniqueWeekYears(element['Start'] as Date, element['End'] as Date),
                        Data: [element]
                    };
                    groups.push(currentGroup);
                }
            }
        }
        return groups;
    }



    static groupElementsByExactDate<T>(elements: T[]): IDatedData<T>[] {

        const groups: IDatedData<T>[] = [];

        // convert string dates to Date
        elements.forEach(e => {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });

        // order elements by start date
        elements = [...elements].sort((a, b) => {
            const aStart = a['Start'] as Date;
            const bStart = b['Start'] as Date;
            return (aStart?.getTime?.() ?? 0) - (bStart?.getTime?.() ?? 0);
        });

        for (const element of elements) {
            // on cherche s'il y a un groupe avec la meme date de début et de fin
            const group = groups.find(g => {
                const gStart = g.Start as Date;
                const gEnd = g.End as Date;
                const elementStart = element['Start'] as Date;
                const elementEnd = element['End'] as Date;
                return gStart?.getTime?.() === elementStart?.getTime?.() && gEnd?.getTime?.() === elementEnd?.getTime?.();
            });

            // si on en a un alors on ajoute l'élément aux data sinon on ajoute un nouveau groupe
            if (group) {
                group.Data.push(element);
            } else {
                groups.push({
                    Start: element['Start'],
                    End: element['End'],
                    Data: [element]
                });
            }
        }
        return groups;
    }

    static AddCurrencyToCells(cellValue: CellValueEngine, ind: IndicateurBase, _data: any[], allCurrencies?: Dictionary<ref_Currencies>) {

        const getElement = (ind: IndicateurBase, prop: string) => <T>(e: T) => {
            const subPropName = ind?.['options']?.['subProperty'];
            const res = subPropName ? e?.[ind?.field + subPropName.replace(/\./g, '') + prop] : e?.[prop];
            return res;
        }

        const data = ind?.options?.linksDescriptor
            ? _data?.flatMap(d => d[ind.options.linksDescriptor.className + '-' + ind.options.linksDescriptor.property])
            : _data;

        switch (ind.valueType) {
            case eKPIType.Price:
            case eKPIType.PriceBound:
                const currencies = distinct(data.map(getElement(ind, 'Currency')).filter(Boolean))
                cellValue.Options = {
                    Currencies: currencies,
                    CurrencyCode: currencies?.length == 1 ? allCurrencies?.[currencies[0]]?.Code : null
                }
                break;
            case eKPIType.PriceReturned:
                const currenciesRet = distinct(data.map(getElement(ind, 'ReturnedCurrency')).filter(Boolean))
                cellValue.Options = {
                    Currencies: currenciesRet,
                    CurrencyCode: currenciesRet?.length == 1 ? allCurrencies?.[currenciesRet[0]]?.Code : null
                }
                break;
        }
    }

    static async FormatCells(documentType: string, indicateur: Indicateur, cells: CellValue[], src?: src_AdwOne) {
        if (!cells?.length) return cells;
        await ReleaseEventLoop();

        // particular case for % direction, force the template to be a percent template
        if (isDirectionPercent(indicateur?.optionsBase?.direction))
            return EngineTools.applyPercentTemplate(cells);

        switch (indicateur.type) {
            case eIndicateurType.info:
                const indicateurInfo = <IndicateurInfo>indicateur;

                if (indicateurInfo?.options?.formater?.type == "moment")
                    return EngineTools.applyMomentTemplate(cells, indicateurInfo);

                let prop: ref_PropertyType;
                if (indicateurInfo?.field?.startsWith?.(`${propertyOf<ref_Messages>('ModelProperties')}.`)) {
                    const propertyTypes: ref_PropertyType[] = await DataProvider.search(ref_PropertyType.name);
                    prop = propertyTypes?.find(p => p.Type === indicateurInfo.field.replace(`${propertyOf<ref_Messages>('ModelProperties')}.`, ""));
                }

                const metadata = await getMetadataMemo(indicateurInfo?.options?.linksDescriptor?.className ?? documentType);
                await EngineTools.FormatCellInfo(cells, metadata, indicateurInfo, src, prop);
                break;
            case eIndicateurType.join:
                await EngineTools.FormatJoinCell(documentType, indicateur as IndicateurJoin, cells, src);
                break;
            case eIndicateurType.kpi:
            case eIndicateurType.discount:
            case eIndicateurType.computed:
                await EngineTools.FormatCellKPIDiscount(cells, <IndicateurComputed>indicateur);
                break;
            case eIndicateurType.link:
                cells?.forEach(c => {
                    if (Array.isArray(c.Value)) c.Formated = (c.Value.length > 0 ? 1 : 0)?.toString();
                    if (typeof c.Value == "number") c.Formated = (c.Value > 0 ? 1 : 0)?.toString();
                    else c.Formated = "";
                });
                break;

            default:
                throw new Error("Not implemented");
        }
        return cells;
    }

    /**
     * Apply a template for moment values
     * @param cells
     * @param indicateurInfo
     * @returns
     */
    private static async applyMomentTemplate(cells: CellValue[], indicateurInfo: IndicateurInfo) {
        if (indicateurInfo?.options?.formater?.type == "moment")
            for await (const cell of splitBlocks(cells)) {
                if (typeof cell.Value != "number" && !isDate(cell.Value)) {
                    if (typeof cell.Value == "string")
                        cell.Formated = cell.Value;
                    continue;
                }

                if (indicateurInfo.options.formater.format) {
                    switch ((indicateurInfo.options.formater as formaterMomentBase).format) {
                        case "MMMM":
                            cell.Formated = Trad("month_" + cell.Value);
                            break;
                        case "MM":
                            cell.Formated = (cell.Value < 10 ? "0" + cell.Value : cell.Value)?.toString();
                            break;
                        case "WW":
                            cell.Formated = Trad("week_very_short") + (cell.Value < 10 ? "0" + cell.Value : cell.Value)?.toString();;
                            break;
                        case "YYYY":
                            cell.Formated = cell.Value?.toString();
                            break;
                        case "DD":
                            // write the day with 2 digits
                            cell.Formated = (cell.Value < 10 ? "0" + cell.Value : cell.Value)?.toString();
                            break;
                        case "[T]Q":
                            cell.Formated = "T" + cell.Value;
                            break;
                        default:
                            cell.Formated = cell.Value?.toString();
                            break;
                    }
                }

                if (indicateurInfo.options.formater.periodicity) {
                    switch ((indicateurInfo.options.formater as formatMomentPeriodicity).periodicity) {
                        case "datedWeek":
                            cell.Formated = Trad("week_of") + ' ' + GetCellTemplate(ePropType.Date)(moment(new Date(cell.Value)).startOf("week"));
                            break;
                        case "semester":
                            cell.Formated = "S" + cell.Value;
                            break;
                        default:
                            break;
                    }
                }
            }
        return cells;
    }

    /**
     * Apply a template for percent values
     * @param cells
     * @returns
     */
    private static async applyPercentTemplate(cells: CellValue[]) {
        const template = GetKPITemplate(eKPIType.Percent);
        for await (const cell of splitBlocks(cells)) {
            if (isNaN(cell.Value))
                cell.Formated = "-";
            else
                cell.Formated = template(cell.Value);
        }
        return cells;
    }

    static async FormatLinkedClassCell(cells: CellValue[], prop: MetaDataProperty, src: src_AdwOne, cellTemplate: (data: any) => string) {

        if (!cellTemplate)
            cellTemplate = (data) => data;

        /* Si on est sur un link alors on récupère les valeurs via le manager pour les formater */
        //const mgr = ManagerFactory.GetInstance().getManager(prop.linkedClass, { collection: src?.URI });
        //Formatage des cellules pour gérer les valeurs qui ne sont pas des rids
        cells.forEach(c => c.Formated = c.Value);
        /** get non empty values */
        const rids = Array.from(new Set(cells
            .filter(c => c?.Value && c?.Value != "" && (typeof c.Value == "string" || c.Value?.length))
            .map(c => c.Value)
            .reduce((a, b) => (Array.isArray(a) ? a : [a]).concat(Array.isArray(b) ? b : [b]), [])
            .filter(v => v)
            .filter(v => IsIDDB(v))));

        let values: IRid[] = [];
        let time0126 = 0;
        let _time0126 = 0;
        if (rids?.length) {

            const dicoMapping = {
                [ref_Periodicity.name]: ref_Property.name
            }

            const properties = propsNeededForFormat[prop.linkedClass] ?? ["Name"];
            await ReleaseEventLoop();
            time0126 = new Date().getTime();

            const className = dicoMapping[prop.linkedClass] ?? prop.linkedClass;

            // get meta data to know if the class has an "Active" property
            const meta = await DataProvider.getMetadata(className);
            const body: any = {
                "@rid": rids,
                properties
            };

            // if the class has an "Active" property, we add [true, false] to the search because we want to get all the elements
            // even if they are not active. (by default the search is done with Active = true if property is not present)
            if (meta.some(m => m.name == "Active"))
                body.Active = [true, false];

            if (src?.URI)
                body.managerProps = { collection: src.URI };

            // Then we get the elements
            const elements = await DataProvider.search(className, body).catch(e => { console.error(e); return [] });

            await ReleaseEventLoop();
            _time0126 = new Date().getTime();
            // console.log(`[PERF] [FORMAT_TABLE] Get ${prop.linkedClass} (${elements.length}) ${_time0126 - time0126}ms`);
            values = Sort(prop.linkedClass, elements);
        }

        // console.log(`[PERF] [FORMAT_TABLE] Dico ${prop.linkedClass} (${values.length}) BEFORE`);
        await ReleaseEventLoop();
        const dicoElements = toDictionary(values, e => e['@rid']);

        // console.log(`[PERF] [FORMAT_TABLE] Dico ${prop.linkedClass} (${values.length}) ${_timedicoend - _timedico}ms`);
        //console.log(`[PERF] [FORMAT_TABLE] Dico ${prop.linkedClass} (${values.length})`);

        const _t1 = new Date().getTime();
        const memoFormat = memoize(
            (element: any) => Format(element, null, prop.linkedClass),
            { key: (element) => element?.['@rid'] });

        await splitBlocksFor(cells, cell => {
            if (cell.Value === "#-1:-1") {
                cell.Formated = "NC";
                return;
            }

            if (Array.isArray(cell.Value)) {
                cell.Formated = JoinElements(cell.Value
                    .map(v => dicoElements[v] ?? v)
                    .map(i => typeof i == 'string' ? i : memoFormat(i))
                    .map(cellTemplate));
            }
            else {
                const element = dicoElements[cell.Value];
                cell.Formated = cellTemplate((element ? memoFormat(element) : cell.Value) ?? "");
            }
        }, 20000);

        const _t1end = new Date().getTime();
        console.log(`[PERF] [FORMAT_TABLE] Format ${cells?.length} cells ${prop.linkedClass} (${values.length}) Request: `, _time0126 - time0126, `ms, Apply: `, _t1end - _t1, ` ms`);
    }

    static GetCellInfoTemplate(indicateur: IndicateurInfo | IndicateurComputed, propertyType?: ref_PropertyType): (data: any) => string {
        const propTemplate = GetPropTemplate(indicateur.field);

        const propTypeTemplate = GetPropertyTypeTemplate(propertyType);
        const kpitemplate = GetKPITemplate(indicateur.valueType);
        let cellTemplate = propTemplate ?? propTypeTemplate ?? kpitemplate ?? GetKPITemplate(eKPIType.String)
        if (indicateur.options?.formater?.type == "html") {
            const cellBase = cellTemplate;
            cellTemplate = (str) => {
                const toHtml = str ? HTMLToString(str) : str;
                return cellBase(toHtml);
            }
        }
        if (indicateur.options?.formater?.type == "trad") {
            const cellBase = cellTemplate;
            cellTemplate = (str) => {
                const formater = indicateur.options.formater as formaterTrad;
                const strValue = str ?? formater.fallback;
                const translated = (strValue != null) ? Trad((formater.prefixe ?? "") + strValue + (formater.suffixe ?? "")) : strValue;
                return cellBase(translated);
            }
        }
        return cellTemplate;
    }

    static async FormatCellInfo(cells: CellValue[], props: MetaDataProperty[], indicateur: IndicateurInfo, src?: src_AdwOne, propertyType?: ref_PropertyType) {
        let prop = indicateur?.options?.["MetaData"] ?? props.find(p => p.name === indicateur.field);

        if (!prop)
            console.warn(`FormatCellInfo: prop not found in metadata: ${indicateur.field}`);

        const cellTemplate = EngineTools.GetCellInfoTemplate(indicateur, propertyType);

        if (prop?.linkedClass && indicateur.valueType == eKPIType.Rid) {
            await EngineTools.FormatLinkedClassCell(cells, prop, src, cellTemplate);
        } else {
            /** si ce n'est pas un link on prend le même template que les tableaux */

            await splitBlocksFor(cells, cell => {
                cell.Formated = Array.isArray(cell.Value) ? JoinElements(cell.Value.map(cellTemplate)) : cellTemplate(cell.Value);
            });

            // for await (const cell of splitBlocks(cells)) {
            //     cell.Formated = Array.isArray(cell.Value) ? JoinElements(cell.Value.map(cellTemplate)) : cellTemplate(cell.Value);
            // }
        }
    }

    static async FormatJoinCell(documentType: string, indicateur: IndicateurJoin, cells: CellValue[], src: src_AdwOne) {
        const allSubCells: CellValue[][] = [];
        for (let i = 0; i < indicateur.indicateurs.length; i++) {
            const subIndicateur = indicateur.indicateurs[i];
            const clonedCells = cells
                .flatMap((c, key) => (toArray(c.Value).every(v => Array.isArray(v)) ? c.Value : [c.Value]).map(v => ({
                    ...c,
                    Value: v[i],
                    flag: key,
                })));

            await EngineTools.FormatCells(documentType, subIndicateur, clonedCells, src);
            allSubCells.push(clonedCells);
        }

        for (let iCell = 0; iCell < cells.length; iCell++) {
            const cell = cells[iCell];
            const colCells = indicateur.indicateurs.map((_, i) => {
                const indicateurCells = allSubCells[i].filter(c => c['flag'] === iCell).map(c => c.Formated);
                return indicateurCells;
            })

            if (colCells?.length) {
                const allValues: string[] = [];
                for (let i = 0; i < colCells[0].length; i++) {
                    const element = colCells.map(c => c[i]).filter(c => Boolean(c) && c != "").join(indicateur.options?.separator ?? " ");
                    allValues.push(element);
                }
                cell.Formated = allValues.join(", ");
            }
        }
    }

    static async FormatCellKPIDiscount(cells: CellValue[], indicateur: IndicateurComputed) {
        const templateDiscount = EngineTools.GetCellInfoTemplate(indicateur); //GetKPITemplate(indicateur.valueType);
        const currenciesDico = await EngineTools.GetCurrencies();
        for await (const cell of splitBlocks(cells)) {
            cell.Formated = templateDiscount?.(cell.Value);
            if (cell.Options?.Currencies?.length == 1) {
                const currency = currenciesDico[cell.Options.Currencies[0]];
                if (currency) {
                    if (!cell.Formated?.includes?.(currency.Code)) {
                        cell.Formated = FormatPrice(cell.Value, currency.Name);
                        // cell.Formated = `${cell.Formated} ${currency.Code}`;
                    }
                    cell.Options.CurrencyCode = currency.Code;
                }
            }
        }
    }

    static BuildLinkPropertyInfo(name: string, options?: propertyOption): { property: string, alias: string, fallbackProperty?: string, fallbackAlias?: string } {
        const property = options?.subProperty ?? "Name";
        let alias = options?.MetaData?.name;
        if (!alias)
            alias = `${name}${property}`.replace(/\./g, '');
        if (options?.subPropertyFallback) {
            const fallbackProperty = `${name}.${options.subPropertyFallback}`;
            const fallbackAlias = `${fallbackProperty}`.replace(/\./g, '');
            return { property, alias, fallbackProperty, fallbackAlias };
        }
        return { property, alias };
    }

    static BuildLinkPropertyParams(name: string, options?: propertyOption) {

        const propInfos = EngineTools.BuildLinkPropertyInfo(name, options);
        let propertyName = `${name}.${propInfos.property}`;
        const aliasProperty = `${propertyName} as ${propInfos.alias}`;
        const properties = [aliasProperty];

        options?.subPropertyDependencies?.forEach?.(dep => {
            properties.push(`${name}.${dep} as ${propInfos.alias}${dep}`)
        })

        if (propInfos.fallbackProperty) {
            const fallbackProperty = `${name}.${propInfos.fallbackProperty} as ${propInfos.fallbackAlias}`
            properties.push(fallbackProperty);
        }
        return properties;
    }

    static BuildPropertiesFromIndicateurs(_indicateurs: Indicateur[]) {
        _indicateurs = _indicateurs?.filter?.(Boolean);
        let res = [
            ...(_indicateurs ?? [])
                .filter((i: IndicateurInfo) => i.options?.subProperty)
                .flatMap((i: IndicateurInfo) => EngineTools.BuildLinkPropertyParams(i.field, i.options)),
            ...(_indicateurs ?? [])
                .flatMap((i: IndicateurInfo) => ([i.options?.subPropertyValueAsKey, ...i.options?.match?.map?.(m => m.subProperty) ?? []]))
                .filter(k => k),
            ...(_indicateurs ?? [])
                .filter(i => i.type == eIndicateurType.info)
                .filter((i: IndicateurInfo) => i.options?.priorityToField)
                .map((i: IndicateurInfo) => i.options?.priorityToField),
            ...((_indicateurs ?? []).map(i => i.field).filter(Boolean))].filter(Boolean);

        const computedIndicateurs = <(IndicateurComputed | IndicateurJoin)[]>(_indicateurs ?? []).filter(i => [eIndicateurType.computed, eIndicateurType.join].includes(i.type));
        if (computedIndicateurs.length > 0)
            res.push(...EngineTools.BuildPropertiesFromIndicateurs(computedIndicateurs.flatMap(i => i.indicateurs)));

        const linkIndicateurs = <IndicateurLink[]>_indicateurs?.filter(i => i.type == eIndicateurType.link);
        if (linkIndicateurs.length > 0)
            res.push(...linkIndicateurs.flatMap(i => i.options?.links?.map(ld => ld.property)));

        const indicateurDiscounts = _indicateurs?.filter(i => i.type == eIndicateurType.discount);
        if (indicateurDiscounts.some(i => !i.options?.["barter"])) {
            res.push("Discounts");
            res.push("DiscountMode");
        }
        if (indicateurDiscounts.some(i => i.options?.["barter"])) {
            res.push("BarterPercents");
        }

        if (_indicateurs?.some(i => i.type == eIndicateurType.kpi))
            res.push("KPIs");
        if (_indicateurs?.some(i => IsPrice(i.valueType)))
            res.push("Currency");
        if (_indicateurs?.some(i => i.valueType == eKPIType.PriceReturned)) {
            //Le calcul du taux de change nécessite de récupérer le groupe annonceur et l'annonceur
            res.push("AdvertiserGroup");
            res.push("Advertiser");
            res.push("ReturnedCurrency");
        }
        if (res.includes("Discounts"))
            res = res.filter(p => !p.startsWith("Discounts."));

        return res;
    }

    static BuildPropertiesFromLabelBuilders(labels: LabelBuilder[]) {
        const properties: string[] = [];
        const indicateurs: Indicateur[] = [];
        for (const label of labels) {
            switch (label?.Type) {
                case "property":
                    properties.push(label.Value);
                    break;
                case "indicateur":
                    indicateurs.push(label.Value);
                    break;
                default:
                    break;
            }
        }
        properties.push(...EngineTools.BuildPropertiesFromIndicateurs(indicateurs));
        return distinct(properties);
    }
}