import * as React from "react";
import { getMultiSelectTreeValue, MultiSelectTree, MultiSelectTreeChangeEvent, MultiSelectTreeExpandEvent, MultiSelectTreeProps, MultiSelectTreeTagProps, TagData } from "@progress/kendo-react-dropdowns";
import { getter } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-react-data-tools";
import { ContainerComponent } from "./VertexGrid/Generic/ContainerComponent";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { Format } from "format-lib/index.bin";
import { duplicate, GetFlatElements, GetHashCode, propertyOf } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { recurse } from "tools-lib";
import { Checkbox, Switch } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";

export type IKey = { key: string | number };

export type MultiSelectItem<T> = {
    label: string,
    dataItem: T,

    checked?: boolean,
    checkIndeterminate?: boolean,
    expanded?: boolean,
    items?: MultiSelectItem<T>[]
} & IKey

const dataItemKey = propertyOf<MultiSelectItem<any>>("key");
const checkField = propertyOf<MultiSelectItem<any>>("checked");
const checkIndeterminateField = propertyOf<MultiSelectItem<any>>("checkIndeterminate");
const subItemsField = propertyOf<MultiSelectItem<any>>("items");
const expandField = propertyOf<MultiSelectItem<any>>("expanded");
const textField = propertyOf<MultiSelectItem<any>>("label");

const fields = {
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
};

type HierarchyPickerProps<T> = {
    value?: IKey[];
    data?: (T & IRid)[];

    type: (new () => T) | string;
    filter?: (item: T) => boolean;
    onSelectedItemsChange?: (selectedItems: T[]) => void;
    params?: any;
    title?: string;
    multiSelectTreeProps?: Partial<MultiSelectTreeProps>;
    className?: string;
    placeholder?: string;
}

type HierarchyPickerState<T> = {
    // value?: IKey[],
    data?: MultiSelectItem<T>[],
    expanded?: any[],
    filter?: any;
}

function mapper<T>(e: T, _filter?: (e: T) => boolean): MultiSelectItem<T> {
    return {
        key: e["@rid"],
        label: Format(e),
        items: e[subItemsField]?.map(i => mapper(i, _filter))?.filter(i => !_filter || _filter(i.dataItem)),
        dataItem: e
    }
}

export function HierarchyPicker<T>({ type, value, data: _data, onSelectedItemsChange, params, filter: _filter, title, className, multiSelectTreeProps, placeholder }: HierarchyPickerProps<T>) {

    const [selectAll, setSelectAll] = React.useState<boolean>(false);
    const [_el, setAnchor] = React.useState<HTMLDivElement>();
    const [state, setState] = React.useState<HierarchyPickerState<T>>({ expanded: [] });
    const { data, expanded, filter } = state;

    React.useEffect(() => {
        // init with props
        if (_data) {
            setState({ ...state, data: _data.map(e => mapper(e, _filter)) });
            return;
        }

        // init with server request
        if (!_data)
            Client.searchVertex(typeof type == "string" ? type : type.name, params)
                .then((res) => setState({ ...state, data: res.data.results.map(e => mapper(e, _filter)) }));
    }, [type, _data])

    const expandedState = (
        item: unknown,
        dataItemKey: string,
        _expanded: unknown[]
    ) => {
        if (!_expanded)
            _expanded = [];
        const nextExpanded = _expanded?.slice() ?? [];
        const keyGetter = getter(dataItemKey);
        const itemKey = keyGetter(item);
        const index = _expanded?.findIndex((currentKey) => currentKey === itemKey);
        index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
        return nextExpanded;
    };

    const onExpandChange = (event: MultiSelectTreeExpandEvent) =>
        setState({ ...state, expanded: expandedState(event.item, dataItemKey, expanded) });

    const processMultiSelectTreeData = (tree, options): any[] => {

        const {
            subItemsField = "items",
            checkField = "checked",
            checkIndeterminateField = "checkIndeterminate",
            expandField = "expanded",
            dataItemKey,
            value,
            filter,
            expanded,
        } = options;

        const recurse = (elements: any[]) => {
            if (elements)
                for (const e of elements) {
                    e[expandField] = expanded?.find(key => key == e[dataItemKey]) != undefined;
                    const checked = recurse(e[subItemsField]);
                    e[checkField] = Boolean((checked > 0 && checked == e[subItemsField].length) || value?.find(v => v[dataItemKey] == e[dataItemKey]));
                    e[checkIndeterminateField] = !e[checkField] && checked > 0;
                }
            return elements?.filter(e => e[checkField])?.length ?? 0;
        }

        recurse(tree);

        const filtering = Boolean(filter && filter.value);
        return filtering ? filterBy(tree, [filter], subItemsField) : tree;
    }

    const treeData = data ? processMultiSelectTreeData(data, { expanded, value, filter, ...fields }) : [];
    const values = [];

    let isSelectAll = true;
    recurse(data, subItemsField, (e) => {
        if (e[checkField])
            values.push(e);
        else
            isSelectAll = false;
    })
    if (isSelectAll != selectAll)
        setSelectAll(isSelectAll);

    const onChange = (event: MultiSelectTreeChangeEvent) => {
        const newValue = getMultiSelectTreeValue(data, { ...fields, ...event, value: values });
        const flatElements = GetFlatElements(data, "items");
        const selectedItems = newValue?.map(v => flatElements.find(d => d.key == v.key)?.dataItem).filter(e => e);
        onSelectedItemsChange?.(selectedItems);
    }

    const filterChange = (event) => {
        event.filter.field = textField;
        if (event.filter.value.length > 2) setState({ ...state, filter: event.filter })
        else setState({ ...state, filter: undefined })
    }

    const onSelectAllChange = (event) => {
        const value = !selectAll;
        if (!value)
            onSelectedItemsChange?.([]);
        else {
            const flatElements = GetFlatElements(data, "items");
            onSelectedItemsChange?.(flatElements.map(d => d.dataItem));
        }
    }

    const tagClassName =
        "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base";
    const focusedTagClassName = tagClassName + " k-focus";
    const preventDefault = (event) => event.preventDefault();
    const stopPropagation = (event) => event.stopPropagation();
    const customTagComponent = (props: MultiSelectTreeTagProps) => {
        const { tagData, guid, focusedTag, onTagDelete } = props;
        return (
            <div role="option" dir="ltr"
                className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base"
                aria-disabled={false}
                aria-checked={false}
            >
                <span>{tagData.text}</span>
            </div>)
    }

    const customTagData = (): TagData[] => {
        if (value?.length)
            return [{ text: `${value.length} ${Trad("elements")}`, data: [] }]
        return [];
    }

    return <div className={"configuration-container " + (className ?? "")} ref={el => setAnchor(el)}>
        <ContainerComponent
            title={title}
            className="configuration-multiselect no-padding">
            <Button style={{ position: "absolute", right: "0", zIndex: "50", display:"block" }}
            className={`${selectAll ? "custom_btn_primary" : "custom_btn_primary_cancel"} no-shadow`} onClick={onSelectAllChange}>
                {Trad("Tous")}</Button>

            <MultiSelectTree
                data={treeData}
                dataItemKey={dataItemKey}
                textField={textField}
                subItemsField={subItemsField}
                checkField={checkField}
                checkIndeterminateField={checkIndeterminateField}
                expandField={expandField}
                filterable={true}
                value={values}
                onChange={onChange}
                onExpandChange={onExpandChange}
                onFilterChange={filterChange}
                tag={customTagComponent}
                tags={customTagData()}
                style={{ width: "100%" }}
                popupSettings={{ appendTo: _el, className: "custom-MultiSelectTree-popup" }}
                {...(multiSelectTreeProps ?? {})} />
        </ContainerComponent>
    </div>
}