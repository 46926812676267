
import { MessageAggregator } from "./MessageAggregator";
import { CampaignAggregator } from "./CampaignAggregator";
import { Indicateur, IndicateurToString } from "adwone-engine/index.bin";
import { eColumnType } from "../models/types.bin";
import { DiscountOptions } from "../models/external.bin";
import { ref_KPIs } from "../models/orientdb/ref_KPIs.bin";
import { lnk_HasKPI } from "../models/orientdb/lnk_HasKPI.bin";
import { ClassProperty } from "../models/VertexProperty.bin";

export type IndicateurOption = { indicateur: Indicateur, columnType: eColumnType, tradKey? : string }

export type cumulIndicateurArgs = {
    computedOptions?: { isPriceReturned?: boolean },
    option?: Partial<IndicateurOption>,
    discountOptions?: Partial<DiscountOptions>
}

export interface IAggregator {
    GetDefaultConfig() : Promise<{
        Columns: Indicateur[],
        Ventilations: Indicateur[]
    }>;
    Provide: () => Promise<IndicateurOption[]>;
    MetadataToIndicateurOptions(): Promise<IndicateurOption[]>;
    UpdateIndicateursNames(indicateurs: Indicateur[], _allIndicateurs?: Indicateur[]);
}

export class AggregatorFactory {
    private _aggregators: { [key: string]: IAggregator } = {};
    private static _instance: AggregatorFactory;

    static GetInstance(): AggregatorFactory {
        if (!AggregatorFactory._instance) {
            AggregatorFactory._instance = new AggregatorFactory();
        }
        return AggregatorFactory._instance;
    }

    Get<T>(objectType: (new () => T) | string): IAggregator {
        const key = typeof objectType == "string" ? objectType : objectType.name;
        if (!this._aggregators[key]) {
            switch (key) {
                case "ref_Messages":
                    this._aggregators[key] = new MessageAggregator();
                    break;
                case "ref_Campaigns":
                    this._aggregators[key] = new CampaignAggregator();
                    break;
            }
        }
        return this._aggregators[key];
    }
}
