import { Indicateur, IndicateurComputed, IndicateurDiscount, IndicateurInfo, IndicateurJoin, IndicateurKPI, eIndicateurType } from "adwone-engine/index.bin";
import { IndicateurOption } from "./AggregatorFactory";
import { eKPI, eKPIType } from "../models/KPIsManager.bin";
import { Trad, TradClassName, TradProp } from "trad-lib";
import { Typed, duplicate, propertiesOf } from "../tools.bin";
import { eColumnType } from "../models/types.bin";
import { ref_AdvertisingCompanyGroups } from "../models/orientdb/ref_AdvertisingCompanyGroups.bin";
import { ePropType } from "../models/VertexProperty.bin";
import { ref_DiscountClasses } from "../models/orientdb/ref_DiscountClasses.bin";
import { eDiscountOptionType, eDiscountOptionValue } from "../models/external.bin";
import { lnk_HasKPI } from "../models/orientdb/lnk_HasKPI.bin";
import { ref_KPIs } from "../models/orientdb/ref_KPIs.bin";
import { ref_Messages } from "../dto/client/ref_Messages.bin";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";
import { clnt_Referentials } from "../dto/client/clnt_Referentials";

export function GetAdCreationII() {
    return Typed<IndicateurInfo>({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.AdCreation' as any, ref_Messages),
        field: 'ModelProperties.AdCreation',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.AdCreationOther'
        }
    });
}

export function GetPlacementCategoryII() {
    return Typed<IndicateurInfo>({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.PlacementCategory' as any, ref_Messages),
        field: 'ModelProperties.PlacementCategory',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.PlacementCategoryOther'
        }
    });
}

export function GetEstimateIIs() {
    return [
        Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('Deversement.Estimate' as any, ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Code"
            }
        }),
        Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('Deversement.EstimateStatus' as any, ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Status",
                formater: {
                    type: "trad"
                }
            }
        }),
        Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('Deversement.EstimateId' as any, ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: 'Import.ExternalID',
                subPropertyFallback: "@rid",
            }
        })
    ]
}

export function GetDuplicatesIO() {
    return Typed<IndicateurOption>({
        indicateur: Typed<IndicateurJoin>({
            field: "Duplicates",
            name: `${Trad("duplicates")}`,
            valueType: eKPIType.String,
            type: eIndicateurType.join,
            indicateurs: [
                Typed<IndicateurInfo>({
                    name: Trad("start"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Date,
                    field: "Start",
                    options: { value: { type: "moment" } }
                }),
                Typed<IndicateurInfo>({
                    name: TradProp("Support"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "Support",
                }),
                Typed<IndicateurInfo>({
                    name: TradProp("BroadcastArea"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "BroadcastArea",
                }),
                Typed<IndicateurInfo>({
                    name: TradProp("Currency"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "Currency",
                }),
                Typed<IndicateurInfo>({
                    name: TradProp("AdvertiserGroup"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "AdvertiserGroup",
                }),
            ],
            options: { separator: " - " }
        }),
        columnType: eColumnType.Property
    })
}

export function GetFiscalMonthIO() {
    const getFiscalMonthField_OI = (field: string) => {
        return Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: Trad('fiscal_month'),
            field: field,
            valueType: eKPIType.Rid,
            options: {
                subProperty: 'ClntData.ModelProperties.fiscal_month',
                MetaData: {
                    linkedClass: clnt_Referentials.name,
                    name: field + "_fiscal_month",
                    type: ePropType.Link
                }
            }
        })
    }
    return Typed<IndicateurOption>({
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurComputed>({
            name: Trad('fiscal_month'),
            valueType: eKPIType.String,
            operator: "||",
            type: eIndicateurType.computed,
            options: {
                formater: {
                    type: "trad",
                    prefixe: "month_",
                    fallback: "0"
                }
            },
            indicateurs: [getFiscalMonthField_OI('Advertiser'), getFiscalMonthField_OI('AdvertiserGroup')]
        })
    });
}

export function GetAdvCompanyGroupIOs() {
    const AdvCompanyGroupComIO = Typed<IndicateurOption>({
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: `${TradClassName(ref_AdvertisingCompanyGroups.name)} Commercial`,
            field: "AdvCompany_Com.first(out('lnk_Hierarchy'))",
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Name",
                MetaData: {
                    linkedClass: ref_AdvertisingCompanyGroups.name,
                    name: "AdvCompanyGroup_Com",
                    type: ePropType.Link
                }
            }
        })
    });
    const AdvCompanyGroupFinIO = Typed<IndicateurOption>({
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: `${TradClassName(ref_AdvertisingCompanyGroups.name)} Financier`,
            field: "AdvCompany_Fin.first(out('lnk_Hierarchy'))",
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Name",
                MetaData: {
                    linkedClass: ref_AdvertisingCompanyGroups.name,
                    name: "AdvCompanyGroup_Fin",
                    type: ePropType.Link
                }
            }
        })
    });
    return [AdvCompanyGroupComIO, AdvCompanyGroupFinIO];
}

export function GetCampaignKPI_IOs(kpi: eKPI, options?: { valueType?: eKPIType, options?: Partial<IndicateurKPI["options"]> }) {
    const valueType = options?.valueType ?? eKPIType.Decimal;
    return Typed<IndicateurOption>({
        columnType: eColumnType.KPI,
        indicateur: Typed<IndicateurKPI>({
            type: eIndicateurType.kpi,
            name: Trad(kpi),
            field: "Campaign",
            valueType,
            options: {
                subProperty: `KPIs.${kpi}`,
                ...(options?.options ?? {}),
                ...(valueType == eKPIType.Price ? ({ subPropertyDependencies: propertiesOf<ref_Campaigns>('Currency') }) : {})
            }
        })
    });
}

export function GetNumberElementsIO() {
    return {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurKPI>({
            name: Trad("number_elements"),
            valueType: eKPIType.Number,
            type: eIndicateurType.kpi,
            options: { rid: undefined, forceValue: 1 }
        })
    };
}

export function GetCOFOIndicateurDiscounts(discountClasses: ref_DiscountClasses[], discountIOs: IndicateurOption[], className: string): { CO: IndicateurDiscount, FO: IndicateurDiscount } {
    const classRid = discountClasses.find(t => t.Name === className)["@rid"];

    const ioCO = discountIOs.filter(p => p.columnType == eColumnType.DiscountValue)
        .map(p => <IndicateurDiscount>p.indicateur)
        .find(p => p.options?.rid == classRid);
    const ioFO = discountIOs.filter(p => p.columnType == eColumnType.DiscountFOValue)
        .map(p => <IndicateurDiscount>p.indicateur)
        .find(p => p.options?.rid == classRid);
    return { CO: ioCO, FO: ioFO };
}

export function GetComputedIO(type: eKPIType, name: string, operator: "+" | "-" | "/" | "*" | "%" | "=" | "|", ios: Indicateur[]) {
    return Typed<IndicateurOption>({
        indicateur: Typed<IndicateurComputed>({
            name: Trad(name),
            valueType: type,
            operator: operator,
            type: eIndicateurType.computed,
            indicateurs: ios
        }),
        columnType: eColumnType.KPI,
        tradKey: name
    })
}

export function GetComputedKpiPriceIO(kpiName: string, lnkKpi: lnk_HasKPI, name: string, operator: "+" | "-" | "/" | "*" | "%" | "=" | "|", ios: Indicateur[]) {
    return GetComputedIO(eKPIType.Price, name, operator, [
        Typed<IndicateurKPI>({
            name: kpiName,
            valueType: eKPIType.Price,
            type: eIndicateurType.kpi,
            field: lnkKpi.Id,
            options: { rid: lnkKpi.KPI }
        }),
        ...ios
    ])
}

export function GetIndicateurKPIName(name: string, kpiType: eKPIType) {
    switch (kpiType) {
        case eKPIType.PriceBound:
            return Trad(name) + ` ${Trad("bound")}`;
        case eKPIType.PriceReturned:
            return Trad(name) + ` ${Trad("returned")}`;
        default:
            return Trad(name);
    }
}

export function GetTimeIOs() {
    /** Colonne Start sous format JJ */
    const DayIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("day_dd"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "DD" } }
        })
    };
    /** Colonne End sous format JJ */
    const DayEndIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("day_dd_end"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "End",
            options: { formater: { type: "moment", format: "DD" } }
        })
    };
    /** Colonne Mois sous format MM */
    const MonthIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("day_m"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "MM" } }
        })
    };
    /** Colonne Mois sous format MMMM */
    const NamedMonthIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("named_month"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "MMMM" } }
        })
    };
    /** Colonne Année sous format YY */
    const YearIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("year"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "YYYY" } }
        })
    };
    /** Colonne Semaine sous format ww */
    const NamedWeekIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("named_week"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "WW" } }
        })
    };
    /** Colonne Semaine sous format ww */
    const DatedWeekIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("dated_week"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", periodicity: "datedWeek" } }
        })
    };
    /** Colonne sous format trimestre */
    const TrimesterIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("trimester"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "[T]Q" } }
        })
    };
    /** Colonne sous format trimestre */
    const SemesterIO: IndicateurOption = {
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurInfo>({
            name: Trad("semester"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", periodicity: "semester" } }
        })
    };
    /** Colonne Nombre de jours */
    const NbDaysIO = Typed<IndicateurOption>({
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurComputed>({
            name: Trad("number_of_days"),
            valueType: eKPIType.Number,
            operator: "-",
            type: eIndicateurType.computed,
            options: { rate: 1 / (1000 * 60 * 60 * 24), round: 'ceil', baseValue: 1000 * 60 * 60 * 24 },
            indicateurs: [
                Typed<IndicateurInfo>({
                    name: Trad("end"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "End",
                    options: { value: { type: "moment" } }
                }),
                Typed<IndicateurInfo>({
                    name: Trad("start"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "Start",
                    options: { value: { type: "moment" } }
                })
            ]
        }),

    })
    /** Colonne Nombre de semaines */
    const NbWeeksIO = Typed<IndicateurOption>({
        columnType: eColumnType.Property,
        indicateur: Typed<IndicateurComputed>({
            name: Trad("number_of_weeks"),
            valueType: eKPIType.Number,
            operator: "-",
            type: eIndicateurType.computed,
            options: { rate: 1 / (1000 * 60 * 60 * 24 * 7), round: 'ceil', baseValue: 1000 * 60 * 60 * 24 },
            indicateurs: [
                Typed<IndicateurInfo>({
                    name: Trad("end"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "End",
                    options: { value: { type: "moment" } }
                }),
                Typed<IndicateurInfo>({
                    name: Trad("start"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "Start",
                    options: { value: { type: "moment" } }
                })
            ]
        })
    })
    return [DayIO, DayEndIO, YearIO, MonthIO, NamedMonthIO, NamedWeekIO, DatedWeekIO, TrimesterIO, SemesterIO, NbDaysIO, NbWeeksIO];
}