import React from 'react';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { DiscountGridRow } from './DiscountGridRow';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../../../redux/store';
import { CustomIconButton } from '../../../Generic/CustomIconButton';
import { AdwAutocomplete, getIcon, TooltipManager } from 'adwone-lib';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { GetNumericFormat } from 'format-lib/index.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { ContainerComponent } from '../../../Generic/ContainerComponent';
import { Trad } from 'trad-lib';
import { ref_DiscountClasses } from 'hub-lib/models/orientdb/ref_DiscountClasses.bin';
import { setNet } from '../../../../../redux/messageEditorSlice';
import { RowSize } from '../../../../../styles/theme';
import { IndicateurToDefinition } from '../../../Generic/ModeleGrid/ModeleGrid';
class DiscountGridRowTitlePropsBase {
    label: string | JSX.Element = '-';
    valueGetter: (m: ref_Messages) => number | string;
}

class DiscountGridRowTitlePropsEditable extends DiscountGridRowTitlePropsBase {
    editable: true;
    baseClasses: ref_DiscountClasses[]
}

type DiscountGridRowTitleProps = DiscountGridRowTitlePropsBase | DiscountGridRowTitlePropsEditable;

export function DiscountGridRowTitle(props: DiscountGridRowTitleProps) {

    const { label, valueGetter, editable, baseClasses } = { editable: false, baseClasses: null, ...props };

    const [inEdit, setInEdit] = React.useState(false);
    const [baseValue, setBaseValue] = React.useState(0);
    const [baseClass, setBaseClass] = React.useState<ref_DiscountClasses>(baseClasses?.find(c => c.Name == "Exceptionnelle"));
    const cofo = useSelector((root: RootState) => root.messageEditor.currentNetType);
    const definition = IndicateurToDefinition(label as string);

    return <DiscountGridRow
        className="discountGridRowTitle"
        rowStyle={{ height: RowSize }}
        labelCell={<span
            style={{ display: 'flex', height: '100%', alignItems: 'center' }}
            className="netdiscount-container"
            onMouseOver={e => TooltipManager.Push({ target: e.target, text: definition })}>
            {Trad(label as string)}
            {inEdit &&
                <ContainerComponent title={Trad("discount_reference")}>
                    <AdwAutocomplete
                        options={baseClasses}
                        value={baseClass}
                        disableClearable
                        getOptionLabel={(option: ref_DiscountClasses) => Trad(option.Name)}
                        onChange={(event, v: any) => setBaseClass(v)}
                    />
                </ContainerComponent>}
        </span>}
        intervalCell={<span className='discountGridRowTitle_cell'>
            {!inEdit && <ValueCell valueGetter={valueGetter} />}
            {inEdit && <NumericTextBox
                className={'CellNumberComponent-editing-cell'}
                spinners={true}
                step={1}
                format={GetNumericFormat(eKPIType.Price)}
                value={baseValue}
                onFocus={() => { }}
                onBlur={() => { }}
                onChange={(e) => setBaseValue(e.value)} />}
        </span>}
        commandCell={!editable ? null :
            <div style={{ textAlign: 'left', alignItems: 'center', display: 'flex', height: '100%' }}>
                {inEdit &&
                    <>
                        <CustomIconButton className="picto custom_btn_secondary_validation no-padding" onClick={() => {
                            // this.setState({ inEdit: false }, () => {
                            //     onChange?.(this.state.base_class, this.state.baseValue);
                            // })
                            store.dispatch(setNet({ base: baseClass, mode: cofo as any, value: baseValue }))
                            setInEdit(false);
                        }}>
                            {getIcon('done')}
                        </CustomIconButton>
                        <CustomIconButton className="picto custom_btn_secondary_cancel no-padding"
                            onClick={() => setInEdit(false)}>
                            {getIcon('undo')}
                        </CustomIconButton>
                    </>}
                {!inEdit &&
                    <CustomIconButton
                        className='primary_color no-padding'
                        onClick={() => {
                            setBaseValue(store.getState().messageEditor.data.KPIs["NetCO"]);
                            setInEdit(true);
                        }}>
                        {getIcon('edit_inline')}
                    </CustomIconButton>
                }
            </div>}
    />
}

function ValueCell({ valueGetter }: { valueGetter: DiscountGridRowTitleProps['valueGetter'] }) {
    const value = useSelector((root: RootState) => {
        const currencyCode = root.messageEditor.currencyCode;
        return valueGetter(root.messageEditor.get()) + ` ${currencyCode ?? ''}`;
    })
    return <>
        {value}
    </>
}